import React from 'react'

import { Alert, Button, Container, Row, Col, Form } from 'react-bootstrap'
import { SCU_ACCOUNT_WIKI_LINK, SCU_PASSWORD_RESET_LINK } from 'util/site/external_links'

export default class Login extends React.Component {
  render() {
    const { auth } = this.props
    const { errorMessage } = auth

    let error = (<></>);
    
    if (errorMessage !== null) {
      error = (<Alert dismissible variant="danger">{ errorMessage }</Alert>)
    }

    return (
      <Container>
        <Row>
          <Col>
            <h1 className="my-5 text-center">SCU Dashboard Login</h1>
            { error }
          </Col>
        </Row>
        <Row>
          <Col>
            <Form onSubmit={ e => this.handleSubmit(e) }>
              <Form.Group controlId="username" ref="username">
                <Form.Label>SCU user name</Form.Label>
                <Form.Control tabIndex="1" placeholder="Enter SCU user name." />
                <Form.Text className="text-muted">Please note: Your SCU credentials are separate from your institutional/CWID credentials. <a href={SCU_ACCOUNT_WIKI_LINK}>Click here for more information</a>. </Form.Text>
              </Form.Group>
              <Form.Group controlId="password" ref="password">
                <Form.Label>Password</Form.Label>
                <Form.Control tabIndex="2" type="password" placeholder="Enter SCU password." />
                <Form.Text className="text-muted"><a href={SCU_PASSWORD_RESET_LINK}>Click here to reset your password.</a></Form.Text>
              </Form.Group>
              <Button tabIndex="3" variant="primary" type="submit">Submit</Button>
            </Form>
          </Col>
        </Row>
      </Container>
    )
  }


  handleSubmit(event) {
    event.preventDefault()

    const form = event.currentTarget;
    const username = form.username.value
    const password = form.password.value

    const { history } = this.props

    const credentials = {
      auth: {
        username: username.trim(),
        password: password.trim()
      }
    }
    this.props.login(credentials)
      .then(() => { history.push("/dashboard") })
  }
}
