import React from 'react'

import PermissionsTable from 'components/token_authority/permissions/permissions_table'
import GroupsPermissionsTable from 'components/token_authority/permissions/groups_permissions_table'

import { Col, Row } from 'react-bootstrap'

export default class UserDetails extends React.PureComponent {
  render() {
    const { user } = this.props

    return (
      <div>
        <Row>
          <Col>
            <h3 className="my-3">{user.name} - Groups</h3>
            <GroupsPermissionsTable groups={user.groups} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="my-3">{user.name} - Additional permissions</h3>
            <PermissionsTable permissions={user.userPermissions}/>
          </Col>
        </Row>
      </div>
    )
  }
}
