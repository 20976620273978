import { CLEAR_STORAGE_QUOTA, RECEIVE_STORAGE_QUOTA } from 'actions/storage_tracker/storage_quota_actions'

const storageQuotaReducer = (state={}, action) => {
  Object.freeze(state)

  switch(action.type) {
    case CLEAR_STORAGE_QUOTA:
      return {}
    case RECEIVE_STORAGE_QUOTA:
      return action.storageQuota
    default:
      return state;
  }
}

export default storageQuotaReducer