import React from 'react'
import Select from 'react-select'
import Treemap from 'components/charts/treemap'
import Table from 'components/tables/table'
import { getLatestReportDate, generateFilesetsTreemapData, generateStorageQuotaTreemapData } from 'util/chart_helpers/storage_chart_helpers.js'
import { generateFilesetsTableData, generateStorageQuotaTableData } from 'util/table_helpers/storage_table_helpers'
import { find } from 'lodash'
// import StorageReportPdf from 'components/storage_tracker/storage_report_pdf/storage_report_pdf_container'

import { Container, Col, Row } from 'react-bootstrap'

export default class StorageDashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedFilesystem: null,
      selectedFileset: null
    }
  }

  componentDidMount () {
    this.props.fetchFilesystems()
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      if (prevProps.filesystems !== this.props.filesystems) {
        this.selectDefaultFilesystem();
      }
    }

    if (prevState !== this.state) {
      if (prevState.selectedFilesystem !== this.state.selectedFilesystem) {
        if (this.state.selectedFilesystem !== null) {
          this.props.fetchFilesets(this.state.selectedFilesystem.value)
        } else {
          this.props.clearFilesets();
        }
      }

      if (prevState.selectedFileset !== this.state.selectedFileset) {
        if (this.state.selectedFileset !== null) {
          this.props.fetchStorageQuota(this.state.selectedFileset.value)
        } else {
          this.props.clearStorageQuota()
        }
      }
    }
  }

  // Select first filesystem automatically. This ensures the map is
  // automatically populated after the filesystems are loaded
  selectDefaultFilesystem = () => {
    const { filesystems } = this.props
    const filesystemKeys = Object.keys(filesystems)

    if (filesystemKeys.length > 0) {
      const filesystem = filesystems[filesystemKeys[0]]
      this.setState({
        selectedFilesystem: {
          label: filesystem.name,
          value: filesystem.id
        }
      })
    }
  }

  handleSelectedFilesystemChange = (selection) => {
    this.setState({
      selectedFilesystem: selection,
      selectedFileset: null
    })
  }

  handleSelectedFilesetChange = (selection) => {
    this.setState({
      selectedFileset: selection
    })
  }

  handleTreemapClick = (e) => {
    const { filesets } = this.props
    
    const { data } = e
    const { name } = data

    const fileset = find(filesets, ['name', name])

    if (fileset) {
      this.setState({
        selectedFileset: {
          label: fileset.name,
          value: fileset.id
        }
      })
    }

  }

  render() {
    const { filesystems, filesets, storageQuota } = this.props
    const { selectedFilesystem, selectedFileset } = this.state

    const filesystemSelectOptions = Object.keys(filesystems)
      .map(k => ({
        label: filesystems[k].name,
        value: filesystems[k].id
      }))

    const filesetSelectOptions = Object.keys(filesets)
      .map(k => ({
        label: filesets[k].name,
        value: filesets[k].id
      }))
    
    
    let latestDate = getLatestReportDate(filesets)
    let treemapData = {}
    let tableData = {}

    if (selectedFileset !== null) {
      treemapData = generateStorageQuotaTreemapData(storageQuota, latestDate)
      tableData = generateStorageQuotaTableData(storageQuota, latestDate)
    } else {
      treemapData = generateFilesetsTreemapData(filesets, latestDate)
      tableData = generateFilesetsTableData(filesets, latestDate)
     } 

    return(
      <Container fluid>
        <Row className="my-3">
          <Col>
            <Select
              name='filesystem-select'
              placeholder='Type or select filesystem name'
              value={selectedFilesystem}
              onChange={this.handleSelectedFilesystemChange}
              options={filesystemSelectOptions}
            />
          </Col>
          <Col>
            <Select
              name='fileset-select'
              placeholder='Type or select fileset name'
              value={selectedFileset}
              onChange={this.handleSelectedFilesetChange}
              options={filesetSelectOptions}
            />
          </Col>
        </Row>
        <Row className="my-3">
          <Col className="treemap"><Treemap treemapData={treemapData} onClick={this.handleTreemapClick} /></Col>
        </Row>
        <Row className="my-3">
          <Col><Table tableData={tableData} /></Col>
        </Row>
      </Container>
    )
  }
}
