import { RECEIVE_SERVICES, RECEIVE_SERVICE } from 'actions/hpc_tracker/service_actions';
import merge from 'lodash/merge';

const serviceReducer = ( state = {}, action ) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_SERVICES:
      return action.services;
    case RECEIVE_SERVICE:
      const newService = { [action.service.id]: action.service };
      return merge({}, state, newService);
    default:
      return state;
  }
};

export default serviceReducer;