import { requestAuthToken } from 'util/auth/login_api_util'

export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE'

export const requestLogin = credentials => ({
  type: LOGIN_REQUEST,
  isFetching: true,
  isAuthenticated: false,
  credentials
})

export const receiveLogin = login => ({
  type: LOGIN_SUCCESS,
  isFetching: false,
  isAuthenticated: true,
  userName: login.userName,
  jwt: login.jwt
})

export const loginError = message => ({
  type: LOGIN_FAILURE,
  isFetching: false,
  isAuthenticated: false,
  message
})

export const loginUser = credentials => dispatch => (
  requestAuthToken(credentials)
    .then((value) => {
      if (!value.jwt) {
        dispatch(loginError(value))
        return Promise.reject();
      } else {
        sessionStorage.setItem('jwt', value.jwt)
        sessionStorage.setItem('userName', value.userName)
        dispatch(receiveLogin(value))
      }
    }, (reason) => {
      dispatch(loginError(reason))
    }
  )
)

export const requestLogout = () => ({
  type: LOGOUT_REQUEST,
  isFetching: true,
  isAuthenticated: true
})

export const receiveLogout = () => ({
  type: LOGOUT_SUCCESS,
  isFetching: false,
  isAuthenticated: false
})

export const logoutUser = () => dispatch => {
  dispatch(requestLogout())
  sessionStorage.removeItem('jwt')
  sessionStorage.removeItem('userName')
  dispatch(receiveLogout())
}
