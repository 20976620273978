import React from 'react'

import { Col, Card, Row, Nav } from 'react-bootstrap'

import Compute from 'components/site/services/compute'
import Develop from 'components/site/services/develop'
import Store from 'components/site/services/store'

const COMPUTE_SERVICE = "compute_service"
const STORAGE_SERVICE = "storage_service"
const DEV_SERVICE = "development_service" 

export default class SiteServices extends React.PureComponent {

  constructor(props) {
    super(props)

    this.state = {
      selectedService: COMPUTE_SERVICE
    }
  }

  handleSelect(eventKey) {
    this.setState({selectedService: eventKey})
  }

  serviceCard = selectedService => {
    switch(selectedService) {
      case COMPUTE_SERVICE:
        return <Compute />
      case STORAGE_SERVICE:
        return <Store />
      case DEV_SERVICE:
        return <Develop />
      default:
        return <Card.Body><Card.Text>Please select a service.</Card.Text></Card.Body>
    }
  }

  render() {
    const { selectedService } = this.state
    const serviceCard = this.serviceCard(selectedService)

    return(
      <Row className="justify-content-md-center my-5">
        <Col md={8}>
          <h1 className="text-center my-5">Explore our services.</h1>
          <p className="text-center my-5">
            We assist researchers at Weill Cornell Medicine with their scientific computing requirements. We provide computation, storage, development resources, application hosting, consulting, and much more.
          </p>
          <Card className="text-center my-5">
            <Card.Header>
              <Nav className="justify-content-center" variant="tabs" fill defaultActiveKey={COMPUTE_SERVICE} onSelect={k => this.handleSelect(k)}>
                <Nav.Item>
                  <Nav.Link eventKey={COMPUTE_SERVICE}>Compute</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={STORAGE_SERVICE}>Store</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey={DEV_SERVICE}>Develop</Nav.Link>
                </Nav.Item>
              </Nav>
            </Card.Header>
            {serviceCard}
          </Card>
        </Col>
      </Row>
    )
  }
}
