import { CLEAR_TRANSACTIONS, RECEIVE_TRANSACTIONS, RECEIVE_TRANSACTION } from 'actions/hpc_tracker/transaction_actions';
import merge from 'lodash/merge';

const transactionReducer = ( state = {}, action ) => {
  Object.freeze(state);
  switch(action.type) {
    case CLEAR_TRANSACTIONS:
      return {};
    case RECEIVE_TRANSACTIONS:     
      return action.transactions;
    case RECEIVE_TRANSACTION:
      const newTransaction = { [action.transaction.id]: action.transaction };
      return merge({}, state, newTransaction);
    default:
      return state;
  }
};

export default transactionReducer;