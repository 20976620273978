import AuthAPIUtil from 'util/auth/auth_api_util'

export const requestPublicKey = () => (
  AuthAPIUtil.get('/public_key.json')
)

export const requestAuthToken = authData => (
  AuthAPIUtil.post('/auth.json', authData)
)

export const validateToken = validationData => (
  AuthAPIUtil.post('/validate.json', validationData)
)