import React from 'react'

import { forEach } from 'lodash'
import { isNullOrUndefined } from 'util';
import Moment from 'react-moment'; // need react-moment library for tags
import moment from 'moment' // meed regular moment library for parsing

const createTooltip = (name, usage, free, datetime) => (
  <div key={name}>
    {/* We need to set the keys, otherwise it'll complain */}
    <strong key={name + 0}>{name}</strong>
    <div key={name + 1}>Usage: {usage} TB</div>
    <div key={name + 2}>Free: {free} TB</div>
    <div key={name + 3}>Reported at: <Moment key={name + 4} format="YYYY/MM/DD h:mm a">{datetime}</Moment></div>
  </div>
)

const createLabelName = (name) => (name.replace(/_/g, " "))

export const getLatestReportDate = (filesets) => {
  let latestDate;
  
  forEach(filesets, (fileset) => {
    if (fileset.latestUsage) {
      let currentDate = moment(fileset.latestUsage.datetime)
      if (!latestDate || currentDate > latestDate) {
        latestDate = currentDate;
      }
    }  
  })

  return latestDate;
}

export const generateFilesetsTreemapData = (filesets, forDate=undefined) => {
  let data = {
    name: 'All filesets',
    color: "#e1e1e1",
    children: []
  }

  const terabyte = 1024 * 1024 * 1024

  forEach(filesets, (fileset) => {
    const filesetName = fileset.name
    const latestUsage = fileset.latestUsage
    const reportedAt = moment(latestUsage.datetime)

    // If we are filtering for a specific date, check early if we'll skip this
    if (forDate && !forDate.isSame(reportedAt)) {
      return; // =continue, see lodash docs
    }

    const filesetCapacity = (latestUsage.storageCapacity / terabyte).toFixed(2)
    const filesetUsed = (latestUsage.storageUsed / terabyte).toFixed(2)    
    const filesetFree = Math.max(0, (filesetCapacity - filesetUsed)).toFixed(2)


    const tooltip = createTooltip(filesetName, filesetUsed, filesetFree, reportedAt)
    const label = createLabelName(filesetName)

    let filesetLeaf = {
      name: filesetName,
      color: "#e1e1e1",
      tooltip: tooltip,
      children: [
        {
          name: `${filesetName}`,
          value: filesetUsed,
          color: "#F7977A",
          tooltip: tooltip,
          label: label
        },
        {
          name: `${filesetName} free (TB)`,
          value: filesetFree,
          color: "#82CA9D",
          tooltip: tooltip
        }
      ]
    }
    data.children.push(filesetLeaf)
  })
 
  return data
}

export const generateStorageQuotaTreemapData = (storageQuota, forDate=undefined) => {
  let data = {
    name: 'All storage quota',
    color: "#e1e1e1",
    children: []
  }

  const terabyte = 1024 * 1024 * 1024

  forEach(storageQuota, (storageQuotum) => {
    // This is for the total quota 'user'
    if (isNullOrUndefined(storageQuotum.latestUsage)) {
      return // =continue, see lodash docs
    }

    const id = storageQuotum.id
    const userName = storageQuotum.user.name
    const latestUsageData = storageQuotum.latestUsage
    const reportedAt = moment(latestUsageData.datetime)

    // If we are filtering for a specific date, check early if we'll skip this
    if (forDate && !forDate.isSame(reportedAt)) {
      return; // =continue, see lodash docs
    }

    const usage = (latestUsageData.storageUsed / terabyte).toFixed(2)
    const capacity = (latestUsageData.storageCapacity / terabyte).toFixed(2)
    
  
    // Occasionally we'll have bad data, ensure this does not crash anything
    if (usage <= 0 && capacity <=0) {
      return //=continue
    }

    const free = Math.max(0, (capacity - usage)).toFixed(2)
    
    const tooltip = createTooltip(userName, usage, free, reportedAt)
    const label = createLabelName(userName)

    let quotumLeaf = {
      name: id,
      color: "#e1e1e1",
      children: [
        {
          name: `${id} used`,
          value: usage,
          color: "#F7977A",
          tooltip: tooltip,
          label: label
        },
        {
          name: `${id} free`,
          value: free,
          color: "#82CA9D",
          tooltip: tooltip
        }
      ]
    }
    data.children.push(quotumLeaf)
  })

  return data
}

