import { forEach } from 'lodash'

export const generatePermissionsTableData = (permissions) => {
  let data = []
  
  if (permissions) {
    forEach(Object.values(permissions), permission => {
      const role = permission.role
      if (role) {
        const applicationId = role.applicationId
        const applicationName = role.applicationName
        const permissionKey = role.key
        const permissionValue = role.value
    
        let entry = {
          applicationId: applicationId,
          applicationName: applicationName,
          permissionKey: permissionKey,
          permissionValue: permissionValue
        }

        data.push(entry)
      }
    }) 
  }
  
  let tableData = {
    data: data,
    columns: [{
        Header: 'Application',
        accessor: 'applicationName'
      }, {
        Header: 'Key',
        accessor: 'permissionKey'
      }, {
        Header: 'Value',
        accessor: 'permissionValue'
      }
    ]
  }
  return tableData
}

export const generateGroupPermissionsTableData = (groups) => {
  let data = []

  if (groups) {
    forEach(Object.values(groups), group => {
      const groupId = group.id
      const groupName = group.name
      const { permissions } = group

      if (permissions) {
        forEach(Object.values(permissions), permission => {
          const { role } = permission
          if (role) {
            data.push({
              groupId: groupId,
              groupName: groupName,
              applicationId: role.applicationId,
              applicationName: role.applicationName,
              permissionKey: role.key,
              permissionValue: role.value
            })
          }
        })
      } else {
        // We have no permissions - only add an entry with the group
        data.push({
          groupId: groupId,
          groupName: groupName
        })
      }
    })
  }

  let tableData = {
    data: data,
    columns: [{
      Header: 'Groups',
      columns: [{
        Header: 'Name',
         accessor: 'groupName'
      }]
    }, {
      Header: 'Permissions',
      columns: [{
        Header: 'Application',
        accessor: 'applicationName'
      }, {
        Header: 'Key',
        accessor: 'permissionKey'
      }, {
        Header: 'Value',
        accessor: 'permissionValue'
      }]
    }]
  }

  return tableData
}