import { RECEIVE_REPORT } from 'actions/hpc_tracker/report_actions';
import merge from 'lodash/merge';

const reportReducer = ( state = {}, action ) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_REPORT:
      const newReport = { [action.report.id]: action.report };
      return merge({}, state, newReport);
    default:
      return state;
  }
};

export default reportReducer;