import TokenAuthorityAPIUtil from 'util/token_authority/token_authority_api_util'

export const fetchApplications = () => (
  TokenAuthorityAPIUtil.get(`/supported_applications.json`)
)

export const fetchApplication = id => (
  TokenAuthorityAPIUtil.get(`/supported_applications/${id}.json`)
)

export const createApplication = (name, description) => {
  let data = {
    supported_application: {
      name: name,
      description: description
    }
  }
  return TokenAuthorityAPIUtil.post(`/supported_applications.json`, data)
}

export const deleteApplication = (id) => (
  TokenAuthorityAPIUtil.delete(`/supported_applications/${id}.json`)
)