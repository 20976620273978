import React from 'react'
import { faChartArea, faHdd, faUserLock } from '@fortawesome/free-solid-svg-icons'
// import { faGitlab } from '@fortawesome/free-brands-svg-icons'

import DashboardNavIcon from 'components/dashboard/dashboard_nav_icon'
import { hasRole } from 'util/auth/auth_util'

import { CardDeck, Container, Col, Row } from 'react-bootstrap'

export default class DashboardNav extends React.Component {
  
  render() {
    const { userToken } = this.props

    let cards = [];

    if (hasRole(userToken, "hpc_usage_tracker", "user")) {
      cards.push(<DashboardNavIcon
        key="storage_tracker"
        link="/storage_tracker" 
        icon={faHdd}
        title="Storage usage"
        text="View storage quota and usage."
      />)
    }

    if (hasRole(userToken, "hpc_usage_tracker", "user")) {
      cards.push(<DashboardNavIcon
        key="hpc_tracker"
        link="/hpc_tracker"
        icon={faChartArea}
        title="Compute usage"
        text="View compute usage."
      />)
    }

    if (hasRole(userToken, "token_authority", "user")) {
      cards.push(<DashboardNavIcon
        key="token_authority"
        link="/token_authority" 
        icon={faUserLock}
        title="Token Authority"
        text="View and manage permissions, groups, and logins."
      />)
    }

    if (cards.length === 0) {
      cards.push(<div>
        No applications available.
      </div>)
    }

    return(
      <Container>
        <Row>
          <Col><h1 className="my-5 text-center">All applications</h1></Col>
        </Row>
        <Row>
          <Col>
            <CardDeck>
              { cards }
            </CardDeck>
          </Col>
        </Row>
      </Container>
    )
  }
}
