import { RECEIVE_DEPARTMENTS, RECEIVE_DEPARTMENT } from 'actions/hpc_tracker/department_actions';
import merge from 'lodash/merge';

const departmentReducer = ( state = {}, action ) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_DEPARTMENTS:
      return action.departments;
    case RECEIVE_DEPARTMENT:
      const newDepartment = { [action.department.id]: action.department };
      return merge({}, state, newDepartment);
    default:
      return state;
  }
};

export default departmentReducer;