import * as ServiceAPIUtil from 'util/hpc_tracker/service_api_util';

export const RECEIVE_SERVICES = 'RECEIVE_SERVICES';
export const RECEIVE_SERVICE = 'RECEIVE_SERVICE';

// Sync actions
export const receiveServices = services => ({
  type: RECEIVE_SERVICES,
  services
});

export const receiveService = service => ({
  type: RECEIVE_SERVICE,
  service
});

// Async actions
export const fetchServices = () => dispatch => (
  ServiceAPIUtil.fetchServices().then(services => dispatch(receiveServices(services)))
);

export const fetchService = id => dispatch => (
  ServiceAPIUtil.fetchService(id).then(service => dispatch(receiveService(service)))
);