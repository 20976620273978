import { connect } from 'react-redux'

import Groups from 'components/token_authority/groups/groups'

import { fetchGroups } from 'actions/token_authority/groups_actions'

const mapStateToProps = state => {
  const { tokenAuthority } = state
  const { groups } = tokenAuthority

  return {
    groups
  }
}

const mapDispatchToProps = dispatch => ({
  fetchGroups: () => dispatch(fetchGroups())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Groups)