import React from 'react';
import { ResponsiveLine } from '@nivo/line'
import _ from 'lodash';

import { uniqueDates, dailyUsage, averageUsage } from 'components/hpc_tracker/charts/transactions/transactions_chart_helpers'

class TransactionsOverTimeChart extends React.PureComponent {
  getDailyUsageData = (transactions, dates) => {
    let usage = []

    // Sum up the usage for each day
    _.forEach(dates, (date) => {
      usage.push({
        x: date,
        y: parseFloat(dailyUsage(transactions, date).toFixed(0))
      })
    })

    return usage
  }

  getAverageUsageData = (transactions, dates) => {
    let usage = []

    // Create average
    if (dates.length > 0) {
      const grandTotal = _.sumBy(transactions, (t) => (t.value))
      const dailyAverage = grandTotal / dates.length
  
      _.forEach(dates, (date) => {
        usage.push({
          x: date,
          y: parseFloat(dailyAverage.toFixed(0))
        })
      })
    }
    return usage
  }

  getRunningAverageUsageData = (transactions, dates) => {
    let usage = []

    // If we cannot calculate the running average, we still need to create
    // some empty data - otherwise, the chart cannot be displayed
    if (dates.length < 3) { 
      _.forEach(dates, (date) => {
        usage.push({ x: date, y: null })
      })
    } else { // This is the running average calculation
      _.forEach(dates, (date, i) => {
        if (date === _.first(dates) || date === _.last(dates)) {
          usage.push({ x: date, y: null })
          return
        }
        const runningAvg = averageUsage(transactions, [dates[i-1], dates[i], dates[i+1]])
        
        usage.push({
          x: date,
          y: parseFloat(runningAvg.toFixed(0))
        })
      })
    }
    return usage
  }

  generateChartData(transactions) {
    // Get all unique dates
    const dates = uniqueDates(transactions)

    // Generate chart series
    let series = []

    series.push({
      id: 'Usage for completed jobs',
      data: this.getDailyUsageData(transactions, dates),
      color: "#E87722"
    })

    if (dates.length >= 3) {
      series.push({
        id: 'Running average',
        data: this.getRunningAverageUsageData(transactions, dates),
        color: "#FFC72C"
      }) 
    } else {
      series.push({
        id: 'Average',
        data: this.getAverageUsageData(transactions, dates),
        color: "#FFC72C"
      })
    }

    return series
  }

  render() {
    const { transactions } = this.props;

    return(
      <ResponsiveLine
        data={ this.generateChartData(transactions) }
        margin={{
            "top": 50,
            "right": 40,
            "bottom": 110,
            "left": 110
        }}
        minY={0}
        stacked={false}
        curve="catmullRom"
        colorBy={(e) => (e.color || "#000")} // Get color from series with default
        axisBottom={{
            "orient": "bottom",
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 60,
        }}
        axisLeft={{
            "orient": "left",
            "tickSize": 5,
            "tickPadding": 5,
            "tickRotation": 0,
            "legend": "CPU seconds used",
            "legendOffset": -80,
            "legendPosition": "center"
        }}
        dotSize={0}
        dotColor="inherit:darker(0.3)"
        dotBorderWidth={2}
        dotBorderColor="#ffffff"
        enableDotLabel={false}
        animate={true}
        motionStiffness={90}
        motionDamping={15}
        legends={[
            {
                "anchor": "bottom",
                "direction": "column",
                "translateY": 110,
                "itemWidth": 80,
                "itemHeight": 20,
                "symbolSize": 12,
                "symbolShape": "circle"
            }
        ]}
      />
    );
  }
}

export default TransactionsOverTimeChart;