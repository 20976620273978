import React from 'react'
import Table from 'components/tables/table'
import { generateGroupPermissionsTableData } from 'util/table_helpers/permissions_table_helpers'

export default class GroupsPermissionsTable extends React.PureComponent {
  render() {
    const tableData = generateGroupPermissionsTableData(this.props.groups)
    return(
      <Table tableData={tableData} />
    )
  }
}