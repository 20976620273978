import React from 'react'
import { ResponsiveTreeMap } from '@nivo/treemap'
import { transactionsForDepartment, transactionsForUser, grandTotal, uniqueKeys } from 'components/hpc_tracker/charts/transactions/transactions_chart_helpers';
import _ from 'lodash'

class TransactionsTreemap extends React.PureComponent {

  // Treemap data is an object with multiple levels of 'children', the last
  // of which holds the 'values' used to generate the treemap.
  // We'll create a treemap by department and user
  generateTreemapData = (transactions) => {
    let data = {
      name: 'Usage',
      children: []
    }

    const departments = uniqueKeys(transactions, 'department.name')
    const users = uniqueKeys(transactions, 'serviceUser.name')

    _.forEach(departments, (departmentName) => {
      let departmentLeaf = {
        name: departmentName,
        children: [] // These will be the users
      }

      // Filter all transactions by department
      const deptTransactions = transactionsForDepartment(transactions, departmentName)
      _.forEach(users, (userName) => {
        // Further filter the department's transactions by user
        const userTransactions = transactionsForUser(deptTransactions, userName)
        departmentLeaf.children.push({
          name: userName,
          value: grandTotal(userTransactions)
        })
      })

      data.children.push(departmentLeaf) // Push the populated department
    })
    
    return data
  }

  render() {
    const { transactions } = this.props;

    return(
      <ResponsiveTreeMap
        root={this.generateTreemapData(transactions)}
        identity="name"
        innerPadding={3}
        outerPadding={3}
        margin={{
            "top": 10,
            "right": 10,
            "bottom": 10,
            "left": 10
        }}
        label="name"
        labelSkipSize={36}
        labelTextColor="inherit:darker(1.2)"
        colors="nivo"
        colorBy="depth"
        borderColor="inherit:darker(0.3)"
        animate={true}
        motionStiffness={90}
        motionDamping={11}
      />
    )
  }
}

export default TransactionsTreemap;