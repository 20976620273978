import React from 'react'

import ApplicationsListItem from 'components/token_authority/applications/applications_list_item'
import ApplicatonsTable from 'components/token_authority/applications/applications_table'
import NewApplicationForm from 'components/token_authority/applications/new_application_form_container'

import { Container, Col, Row } from 'react-bootstrap'

export default class Applications extends React.Component {
  componentDidMount() {
    this.props.fetchApplications()
  }

  render() {
    const { applications } = this.props

    const applicationsList = Object.keys(applications)
      .map(k => (
        <ApplicationsListItem 
          key={applications[k].name}
          application={applications[k]}
        />
      ))

    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="my-5">All applications</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <ApplicatonsTable applications={applications} />
            {/* { applicationsList } */}
          </Col>
        </Row>
        <Row>
          <Col>
            <NewApplicationForm />
          </Col>
        </Row>
      </Container>
    )
  }
}
