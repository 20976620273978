import React from 'react'

import { Col, Jumbotron, Row } from 'react-bootstrap'
export default class SiteHero extends React.PureComponent {
  render() {
    return(
      <Row>
        <Col className="p-0">
          <Jumbotron className="hero" fluid>
          <div className="image-background" />
          <div className="hero-content">
            <h1>Welcome to the Scientific Computing Unit!</h1>
            <p>We provide high performance scientific computing to researchers at Weill Cornell Medicine through cutting-edge computational resources, fast on-premises storage, software deployment, and development consulting. Our team of engineers and developers can assist you in all stages of your HPC projects. We will forecast system requirements, provision, deploy, and monitor hardware as well as support your software development projects.</p>
          </div>
          </Jumbotron>
        </Col>
      </Row>
    )
  }
}
