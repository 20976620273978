import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import App from 'components/app/app'

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    auth
  }
}

const mapDispatchToProps = dispatch => ({
})

// This needs to use `withRouter`, otherwise redirects won't work
export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(App))