import React from 'react'
import ReactTable from 'react-table';

export default class Table extends React.PureComponent {
  render() {
    const { tableData, loading } = this.props
    const { data, columns, defaultSortOptions, pivotBy, defaultPageSize, subcomponent } = tableData

    return(
      <ReactTable
        noDataText="No data selected."
        loading={loading}
        data={data}
        columns={columns || []}
        defaultSorted={defaultSortOptions}
        pivotBy={pivotBy}
        defaultPageSize={defaultPageSize || 10}
        className="-striped -highlight"
        SubComponent={subcomponent}
      />
    )
  }
}