import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { STORE_WIKI_LINK, SCU_MAILTO_LINK } from 'util/site/external_links'

export default class Store extends React.PureComponent {
  render() {
    return(
      <Card.Body>
        <Card.Title className="my-4">From hot on-premises GPFS store to archival cloud storage.</Card.Title>
        <Card.Text>
          We provide a high-performance, on-premises data storage system with over 3.5 PB usable capacity. It consists of a DDN SFA12KX with 840 drives. It is managed by four GPFS NSD servers, connected to clients via a 56 Gbps Inifiniband fabric.
        </Card.Text>
        <Card.Text>
          We can also assist with moving data to cloud-based storage providers, offering inexpensive long-term archival storage.
        </Card.Text>
        <Card.Text>
          Please see our list of storage services for all details, or <a href={SCU_MAILTO_LINK}>get in touch</a> if you'd like to learn more!
        </Card.Text>
        <Button className="my-4" variant="primary" href={STORE_WIKI_LINK}>Details about Storage Services</Button>
      </Card.Body>
    )
  }
}
