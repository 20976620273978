import StorageAPIUtil from 'util/storage_tracker/storage_api_util'

export const fetchUserUsageStatements = () => (
  StorageAPIUtil.get('/user_storage_statements.json')
)

export const fetchUserUsageStatement = statementId => (
  StorageAPIUtil.get(`/user_storage_statements/${statementId}.json`)
)

export const fetchFilesetUsageStatements = () => (
  StorageAPIUtil.get('/fileset_storage_statements.json')
)