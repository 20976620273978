import React, { Component } from 'react'
import { Route, Redirect, Switch } from 'react-router-dom'
import CurrentUser from 'components/token_authority/users/current_user_container'

import InternalFooter from 'components/internal_footer/internal_footer'
import InternalNavbar from 'components/internal_navbar/internal_navbar_container'
import Applications from 'components/token_authority/applications/applications_container'
import ApplicationDetail from 'components/token_authority/applications/application_detail_container'
import Groups from 'components/token_authority/groups/groups_container'
import Users from 'components/token_authority/users/users_container'

import { Container, Col, Row } from 'react-bootstrap'

export default class TokenAuthority extends Component {
  render() {
    const secondaryNav = {
      title: "Token Authority",
      links: {
        "My Account": "/token_authority/my_account",
        "Users": "/token_authority/users",
        "Groups": "/token_authority/groups",
        "Applications": "/token_authority/applications"
      }
    }

    return (
      <>
        <InternalNavbar secondaryNav={secondaryNav} />
        <Container fluid>
          <Row>
            <Col>
              <Switch>
                <Route exact path="/token_authority/my_account" component={CurrentUser} />
                <Route exact path="/token_authority/users" component={Users} />
                <Route exact path="/token_authority/groups" component={Groups} />
                <Route path="/token_authority/applications/:applicationId" component={ApplicationDetail} />
                <Route exact path="/token_authority/applications" component={Applications} />
                {/* Redirect to 'my account' by default */}
                <Redirect to="/token_authority/my_account" /> 
              </Switch>       
            </Col>
          </Row>
        </Container>
        <InternalFooter />
      </>
    )
  }
}