import React from 'react'

import GroupsTable from 'components/token_authority/groups/groups_table'

import { Container, Col, Row } from 'react-bootstrap'

export default class Groups extends React.Component {
  componentDidMount() {
    this.props.fetchGroups()
  }

  render() {
    const { groups } = this.props

    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="my-5">All groups</h2>
          </Col>
        </Row>
        <Row>
          <Col>
            <GroupsTable groups={groups} />
          </Col>
        </Row>
      </Container>
    )
  }
}
