import React from 'react';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import Moment from 'react-moment';
import 'moment-timezone';
import _ from 'lodash';

class TransactionList extends React.Component {
  render() {
    const { transactions } = this.props;

    const columns = [{
      Header: 'Date',
      accessor: 'date',
      Cell: row => (<Moment format="YYYY/MM/DD">{ row.value }</Moment>)
    }, {
      Header: 'Department',
      accessor: 'department.name'
    }, {
      Header: 'Project',
      accessor: 'project.name',
      aggregate: vals => _.join(vals, ', ') // Join all project names in list
    }, {
      Header: 'Service User',
      accessor: 'serviceUser.name',
      aggregate: vals => _.join(vals, ', ') // Join all user names in list
    }, {
      Header: 'Note',
      accessor: 'note',
      Aggregated: row => (<span></span>) // Nothing to display if aggregated
    }, {
      Header: 'Values',
      accessor: 'value',
      aggregate: vals => _.sum(vals),
      Aggregated: row => {
        return (
          <span>
            { row.value } (sum)
          </span>
        );
      }
    }];

    const defaultSortOptions = [
      {
        id: 'date',
        asc: true
      }
    ]

    return(
      <ReactTable 
        noDataText="No transactions found."
        data={ transactions }
        columns={ columns }
        defaultSorted={ defaultSortOptions }
        pivotBy={ ['date', 'department.name'] }
        defaultPageSize={25}
        className="-striped -highlight"
      />
    );
  }
}

export default TransactionList;