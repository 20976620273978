import React from 'react'
import { LinkContainer } from "react-router-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"

import Logout from 'components/auth/logout_container'

import { Nav, Navbar, NavDropdown } from 'react-bootstrap'

import { map } from 'lodash'

export default class InternalNavbar extends React.Component {

  // Create primary navigation
  // Does not have any dynamic elements; broken out in method for clarity
  createPrimaryNav = () => {
    return(
      <NavDropdown className="h5 mx-3" title="All Apps" id="apps-nav-dropdown">
        <LinkContainer to="/storage_tracker">
          <NavDropdown.Item>Storage Tracker</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/hpc_tracker">
          <NavDropdown.Item>Compute Tracker</NavDropdown.Item>
        </LinkContainer>
        <LinkContainer to="/token_authority">
          <NavDropdown.Item>Token Authority</NavDropdown.Item>
        </LinkContainer>
        <NavDropdown.Divider />
        <LinkContainer to="/dashboard">
          <NavDropdown.Item>Dashboard</NavDropdown.Item>
        </LinkContainer>
      </NavDropdown>
    )
  }

  // Create secondary navigation
  // Expects the following input:
  // {
  //   title: "Secondary Nav Title",
  //   links: {
  //     "First Link Name": "/first/link/destination",
  //     "Second Link Name": "/second/link/destination"
  //   }
  // }
  // If no links are included, only the title is displayed.
  createSecondaryNav = (secondaryNav = {}) => {
    const { title, links } = secondaryNav;

    if (!title) { // If title is not given, don't show anything
      return (<></>)
    } else if (!links) { // If no links are given, only show title
      return (<Navbar.Text className="h5 mx-3 text-white">{ title }</Navbar.Text>)
    } else {
      // Map links to nav items
      const navItems = map(links, (v, k) => {
        return (
          <LinkContainer key={v} to={v}>
            <NavDropdown.Item>{k}</NavDropdown.Item>
          </LinkContainer>
        )
      })
      
      // Encapsulate nav items in dropdown
      return (
        <NavDropdown title={title} className="h5 mx-3" id="secondary-nav-dropdown">
          {navItems}
        </NavDropdown>
      )
    }        
  }

  render() {
    const { secondaryNav } = this.props
    
    return(
      <Navbar csticky="top" variant="dark" bg="primary" expand="md">
        <Navbar.Toggle className="ml-auto" as="button" variant="secondary" aria-controls="internal-nav">
          <FontAwesomeIcon icon={faBars} />
        </Navbar.Toggle>
        <Navbar.Collapse id="internal-nav">
          <Nav className="mr-auto">
            { this.createPrimaryNav() }
            { this.createSecondaryNav(secondaryNav) }
          </Nav>
          <Nav className="ml-auto">
            <Logout />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
