import React from 'react'
import ReactDOM from 'react-dom'

import { Button, Form } from 'react-bootstrap'

export default class NewApplicationForm extends React.Component {  

  render() {
    return (
      <Form 
        ref={ form => this.newApplicationForm = form }
        onSubmit={ e => this.handleSubmit(e) } >
        <Form.Group controlId="appName" ref="appName">
          <Form.Label>New Application Name</Form.Label>
          <Form.Control placeholder="Enter name" />
        </Form.Group>
        <Form.Group controlId="appDescription" ref="appDescription">
          <Form.Label>Description</Form.Label>
          <Form.Control as="textarea" rows="3" />
        </Form.Group>
        <Button variant="primary" type="submit">Submit</Button>
      </Form>
    )
  }

  resetForm() {
    ReactDOM.findDOMNode(this.newApplicationForm).reset()
  }

  handleSubmit(event) {
    event.preventDefault();

    const form = event.currentTarget
    const newAppName = form.appName.value
    const newAppDescription = form.appDescription.value

    this.props.createApplication(newAppName, newAppDescription)
      .then((success) => {
        // reset form after successful creation
        this.resetForm()
      }, (error) => {
        // don't reset form - user might want to fix typos
      })
  }
}