import { connect } from 'react-redux'

import Applications from 'components/token_authority/applications/applications'

import { fetchApplications } from 'actions/token_authority/applications_actions'

const mapStateToProps = state => { 
  const { tokenAuthority: { applications } = {} } = state

  return {
    applications
  }
}

const mapDispatchToProps = dispatch => ({
  fetchApplications: () => dispatch(fetchApplications())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Applications)