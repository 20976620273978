import * as FilesetAPIUtil from 'util/storage_tracker/fileset_api_util'

export const CLEAR_FILESETS = 'CLEAR_FILESETS'
export const RECEIVE_FILESETS = 'RECEIVE_FILESETS';
export const RECEIVE_FILESET = 'RECEIVE_FILESET';

// Sync actions
export const clearFilesets = () => ({
  type: CLEAR_FILESETS
})

export const receiveFilesets = filesets => ({
  type: RECEIVE_FILESETS,
  filesets
})

export const receiveFileset = fileset => ({
  type: RECEIVE_FILESET,
  fileset
})

// Async actions
export const fetchFilesets = (filesystemId) => dispatch => (
  FilesetAPIUtil.fetchFilesets(filesystemId)
    .then(filesets => dispatch(receiveFilesets(filesets)))
)

export const fetchFileset = id => dispatch => (
  FilesetAPIUtil.fetchFileset(id)
    .then(fileset => dispatch(receiveFileset(fileset)))
)