import React from 'react'
import Table from 'components/tables/table'
import { generatePermissionsTableData } from 'util/table_helpers/permissions_table_helpers'

export default class PermissionsTable extends React.PureComponent {
  render() {
    const tableData = generatePermissionsTableData(this.props.permissions)
    return(
      <Table tableData={tableData} />
    )
  }
}