import axios from 'axios'

class AuthAPIUtil {
  constructor() {
    let service = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      responseType: 'json',
      data: {}
    })
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  handleSuccess = (response) => {
    return response.data
  }

  handleError = (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          return Promise.reject("401/Unauthorized: Check user name and password.")
        case 403:
          return Promise.reject("403/Forbidden: Check user permissions.")
        default:
          break;
      }
    }
    return Promise.reject(error)
  }

  get = (path) => (this.service.get(path))
  post = (path, data) => (this.service.post(path, data))
  put = (path, data) => (this.service.put(path, data))
  delete = (path) => (this.service.delete(path))
}

export default new AuthAPIUtil()
