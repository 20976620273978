import React, { Component } from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'

import InternalFooter from 'components/internal_footer/internal_footer'
import InternalNavbar from 'components/internal_navbar/internal_navbar_container'
import StorageDashboard from 'components/storage_tracker/dashboard/storage_dashboard_container'
import StorageStatements from 'components/storage_tracker/storage_statements/storage_statements_container'
import { Container, Col, Row } from 'react-bootstrap'

export default class StorageTracker extends Component {
  render() {
    const secondaryNav = {
      title: "Storage Tracker",
      links: {
        "Dashboard": "/storage_tracker/dashboard",
        "Statements": "/storage_tracker/statements"
      }
    }

    return (
      <>
        <InternalNavbar secondaryNav={secondaryNav} />
        <Container fluid>
          <Row>
            <Col>
              <Switch>
                <Route exact path="/storage_tracker/dashboard" component={StorageDashboard} />
                <Route exact path="/storage_tracker/statements" component={StorageStatements} />
                <Redirect to="/storage_tracker/dashboard" /> 
              </Switch>
            </Col>
          </Row>
        </Container>
        <InternalFooter />
      </>
    )
  }
}