import { forEach } from 'lodash'

export const generateApplicationsTable = (applications) => {
  let data = []
  
  if (applications) {
    forEach(Object.values(applications), application => {
      let entry = {
        applicationId: application.id,
        applicationName: application.name
      }

      data.push(entry)
    }) 
  }
  
  let tableData = {
    data: data,
    columns: [{
        Header: 'id',
        accessor: 'applicationId'
      }, {
        Header: 'Name',
        accessor: 'applicationName'
      }
    ]
  }
  return tableData
}