import React from 'react'

import SitePublications from 'components/site/site_publications'
import SiteFaq from 'components/site/site_faq'
import SiteNavbar from 'components/site/site_navbar'
import SiteHero from 'components/site/site_hero'
import SiteServices from 'components/site/site_services'
import SiteFooter from 'components/site/site_footer'

import { Container } from 'react-bootstrap'

export default class Site extends React.PureComponent {
  render() {
    return (
      <>
        <SiteNavbar />
        <Container fluid>
          <SiteHero />
          <div id="publications"></div>
          <SitePublications />
          <hr id="services" />
          <SiteServices />
          <hr id="faq" />
          <SiteFaq />
          <hr />
          <SiteFooter />
        </Container>
      </>
    )
  }
}