import _ from 'lodash'

// Get all unique keys from transactions, p.e. unique dates or users
export const uniqueKeys = (transactions, key) => {
  return _.sortBy(_.uniq(_.map(transactions, key)))
}

// Get all unique dates from transactions
export const uniqueDates = (transactions) => {
  return uniqueKeys(transactions, 'date')
}

export const transactionsForDepartment = (transactions, departmentName) => {
  return _.filter(transactions, (t) => (_.get(t, 'department.name') === departmentName))
}

export const transactionsForUser = (transactions, userName) => {
  return _.filter(transactions, (t) => (_.get(t, 'serviceUser.name') === userName))
}

export const transactionsForProject = (transactions, projectName) => {
  return _.filter(transactions, (t) => (_.get(t, 'project.name') === projectName))
}

// Sum up daily usage by date
export const dailyUsage = (transactions, date) => {
  return _.sumBy(transactions, (t) => {
    if (_.get(t, 'date') === date) {
      return t.value
    }
    return 0
  })
}

// Get the average usage for an array of dates
export const averageUsage = (transactions, dates) => {
  if (dates.length <= 0) return 0

  return (_.sumBy(transactions, (t) => {
      if (_.includes(dates, _.get(t, 'date'))) {
        return t.value
      }
      return 0
    })
  ) / dates.length
}

export const grandTotal = (transactions) => {
  return (_.sumBy(transactions, (t) => (t.value)))
}