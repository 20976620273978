import React from 'react'

import { forEach } from 'lodash'
import { isNullOrUndefined } from 'util'
import Moment from 'react-moment'; // need react-moment library for tags
import moment from 'moment' // meed regular moment library for parsing

import StorageStatementButton from 'components/storage_tracker/storage_statements/storage_statement_button'

const terabyte = 1024 * 1024 * 1024

const percentageCell = percent => (
  <div
    style={{
      width: '100%',
      height: '100%',
      backgroundColor: '#dadada',
      borderRadius: '2px'
    }}
  >
    <div
      style={{
        width: `${percent}%`,
        height: '100%',
        backgroundColor: percent > 66 ? '#ff2e00'
          : percent > 33 ? '#ffbf00'
          : '#85cc00',
        borderRadius: '2px',
        transition: 'all .2s ease-out'
      }}
    />
  </div>
)

export const generateFilesetsTableData = (filesets, forDate=undefined) => {
  let filesetData = []
  
  forEach(Object.values(filesets), fileset => {
    const name = fileset.name
    const usageData = fileset.latestUsage
    const dateReported = moment(usageData.datetime)
    
    // If we are filtering for a specific date, check early if we'll skip this
    if (forDate && !forDate.isSame(dateReported)) {
      return; // =continue, see lodash docs
    }

    const capacity = usageData.storageCapacity
    const usage = usageData.storageUsed
    
    const percentUsed = (capacity <= 0) ? 100 : (usage/capacity) * 100

    let entry = {
      name: name,
      usage: usage,
      capacity: capacity,
      percentUsed: percentUsed,
      dateReported: dateReported
    }
    
    filesetData.push(entry)
  }) 
  
  let tableData = {
    data: filesetData,
    columns: [{
        Header: 'Name',
        accessor: 'name'
      }, {
        Header: 'Capacity',
        accessor: 'capacity',
        Cell: row => (<span>{
          `${(row.value / terabyte).toFixed(2)} TB`
        }</span>)
      }, {
        Header: 'Usage',
        accessor: 'usage',
        Cell: row => (<span>{
          `${(row.value / terabyte).toFixed(2)} TB`
        }</span>)
      }, {
        Header: 'Percent used',
        accessor: 'percentUsed',
        Cell: row => percentageCell(row.value)
      }, {
        Header: 'Reported at',
        accessor: 'dateReported',
        Cell: row => (<Moment format="YYYY/MM/DD h:mm a">{row.value}</Moment>)
      }
    ]
  }

  return tableData
}

export const generateStorageQuotaTableData = (storageQuota, forDate=undefined) => {
  let storageQuotaData = []

  forEach(Object.values(storageQuota), storageQuotum => {
    if (isNullOrUndefined(storageQuotum.user)) return
    if (isNullOrUndefined(storageQuotum.latestUsage)) return
    const name = storageQuotum.user.name
    // Skip if this is the total_fileset quotum
    if (name === 'total_fileset') return
    
    const usageData = storageQuotum.latestUsage
    const dateReported = moment(usageData.datetime)
    
    // If we are filtering for a specific date, check early if we'll skip this
    if (forDate && !forDate.isSame(dateReported)) {
      return; // =continue, see lodash docs
    }

    const capacity = usageData.storageCapacity
    const usage = usageData.storageUsed
    const percentUsed = (capacity <= 0) ? 100 : (usage/capacity) * 100

    let entry = {
      name: name,
      usage: usage,
      capacity: capacity,
      percentUsed: percentUsed,
      dateReported: dateReported
    }

    storageQuotaData.push(entry)
  })

  let tableData = {
    data: storageQuotaData,
    columns: [{
        Header: 'Name',
        accessor: 'name'
      }, {
        Header: 'Capacity',
        accessor: 'capacity',
        Cell: row => (<span>{
          `${(row.value / terabyte).toFixed(2)} TB`
        }</span>)
      }, {
        Header: 'Usage',
        accessor: 'usage',
        Cell: row => (<span>{
          `${(row.value / terabyte).toFixed(2)} TB`
        }</span>)
      }, {
        Header: 'Percent used',
        accessor: 'percentUsed',
        Cell: row => percentageCell(row.value)
      }, {
        Header: 'Reported at',
        accessor: 'dateReported',
        Cell: row => (<Moment format="YYYY/MM/DD h:mm a">{row.value}</Moment>)
      }
    ]
  }

  return tableData
}

export const generateUserStorageStatementsTableData = (storageStatements) => {
  let storageStatementData = []

  forEach(Object.values(storageStatements), storageStatement => {
    if (!storageStatement.statementAvailable) {
      return; // =continue, see lodash docs
    }

    let urls = []

    if (storageStatement.files) {  
      forEach(Object.values(storageStatement.files), file => {
        if (file.url) {
          urls.push(file.url)
        }
      })
    }

    let entry = {
      id: storageStatement.id,
      date: storageStatement.date,
      userName: storageStatement.userName,
      urls: urls
    }

    storageStatementData.push(entry)

  })

  const tableData = {
    data: storageStatementData,
    columns: [{
        Header: 'User name',
        accessor: 'userName',
        filterable: true
      }, {
        Header: 'Date',
        accessor: 'date',
        filterable: true
      }, {
        Header: 'Download PDF',
        accessor: 'urls',
        Cell: row => (<StorageStatementButton urls={row.value} />)
      }
    ],
    defaultSortOptions: [{
      id: 'date',
      desc: true
    }]
  }

  return tableData
}

export const generateFilesetStorageStatementsTableData = (storageStatements) => {
  let storageStatementData = []

  forEach(Object.values(storageStatements), storageStatement => {
    if (!storageStatement.statementAvailable) {
      return; // =continue, see lodash docs
    }

    let urls = []

    if (storageStatement.files) {  
      forEach(Object.values(storageStatement.files), file => {
        if (file.url) {
          urls.push(file.url)
        }
      })
    }

    let entry = {
      id: storageStatement.id,
      date: storageStatement.date,
      filesetName: storageStatement.filesetName,
      filesystemName: storageStatement.filesystemName,
      urls: urls
    }

    storageStatementData.push(entry)

  })

  const tableData = {
    data: storageStatementData,
    columns: [{
        Header: 'Fileset name',
        accessor: 'filesetName',
        filterable: true
      }, {
        Header: 'Filesystem name',
        accessor: 'filesystemName',
        filterable: true
      }, {
        Header: 'Date',
        accessor: 'date',
        filterable: true
      }, {
        Header: 'Download PDF',
        accessor: 'urls',
        Cell: row => (<StorageStatementButton urls={row.value} />)
      }
    ],
    defaultSortOptions: [{
      id: 'date',
      desc: true
    }]
  }

  return tableData
}