import * as PageAPIUtil from 'util/api/page_api_util'

export const RECEIVE_PAGES = 'RECEIVE_PAGES'
export const RECEIVE_PAGE = 'RECEIVE_PAGE'

// Sync actions
export const receivePages = pages => ({
  type: RECEIVE_PAGES,
  pages
})

export const receivePage = page => ({
  type: RECEIVE_PAGE,
  page
})

// Async actions
export const fetchPages = () => dispatch => (
  PageAPIUtil.fetchPages().then(pages => dispatch(receivePages(pages)))
)

export const fetchPage = id => dispatch => (
  PageAPIUtil.fetchPage(id).then(page => dispatch(receivePage(page)))
)