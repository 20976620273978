import { connect } from 'react-redux'

import StorageDashboard from 'components/storage_tracker/dashboard/storage_dashboard'

import { fetchFilesystems } from 'actions/storage_tracker/filesystems_actions'
import { clearFilesets, fetchFilesets } from 'actions/storage_tracker/filesets_actions'
import { clearStorageQuota, fetchStorageQuota } from 'actions/storage_tracker/storage_quota_actions'

const mapStateToProps = (state, ownProps) => {
  const { storageTracker } = state;
  const { filesystems, filesets, storageQuota } = storageTracker;
  
  return {
    filesystems,
    filesets,
    storageQuota
  };
}

const mapDispatchToProps = dispatch => ({
  fetchFilesystems: () => dispatch(fetchFilesystems()),
  clearFilesets: () => dispatch(clearFilesets()),
  fetchFilesets: filesystemId => dispatch(fetchFilesets(filesystemId)),
  clearStorageQuota: () => dispatch(clearStorageQuota()),
  fetchStorageQuota: filesetId => dispatch(fetchStorageQuota(filesetId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageDashboard)