import axios from 'axios'

class TokenAuthorityAPIUtil {
  constructor() {
    let service = axios.create({
      baseURL: process.env.REACT_APP_AUTH_API,
      responseType: 'json',
      data: {}
    })
    service.interceptors.request.use(this.addAuth)
    service.interceptors.response.use(this.handleSuccess, this.handleError)
    this.service = service
  }

  addAuth = (config) => {
    config.headers.Authorization = "Bearer " + sessionStorage.getItem('jwt')
    return config
  }

  handleSuccess = (response) => {
    return response.data
  }

  handleError = (error) => {
    if (error.response && error.response.status) {
      switch (error.response.status) {
        case 401:
          this.forceLogout()
          this.redirectTo(document, process.env.REACT_APP_LOGIN_ROUTE)
          break;
        case 403:
          
          break;
        default:
          break;
      }
    }
    return Promise.reject(error)
  }

  forceLogout = () => {
    sessionStorage.removeItem('jwt')
    sessionStorage.removeItem('userName')
  }

  redirectTo = (document, path) => {
    document.location = path
  }

  get = (path) => (this.service.get(path))
  post = (path, data) => (this.service.post(path, data))
  put = (path, data) => (this.service.put(path, data))
  delete = (path) => (this.service.delete(path))
}

export default new TokenAuthorityAPIUtil()