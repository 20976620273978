import React, { Component } from 'react'

import InternalFooter from 'components/internal_footer/internal_footer'
import InternalNavbar from 'components/internal_navbar/internal_navbar_container'
import UsageDashboard from 'components/hpc_tracker/dashboards/usage/usage_dashboard_container'

export default class HpcTracker extends Component {
  render() {

    const secondaryNav = {
      title: "Compute Tracker",
    }

    return (
      <>
        <InternalNavbar secondaryNav={secondaryNav} />
        <UsageDashboard />
        <InternalFooter />
      </>
    )
  }
}

