import React, { Component } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { AuthRoute, UnauthRoute } from 'react-router-auth'

import Dashboard from 'components/dashboard/dashboard_container'
import HpcTracker from 'components/hpc_tracker/hpc_tracker'
import TokenAuthority from 'components/token_authority/token_authority'
import StorageTracker from 'components/storage_tracker/storage_tracker'
import Site from 'components/site/site'
import Login from 'components/auth/login_container'

class App extends Component {

  isAuthenticated() {
    return this.props.auth.isAuthenticated;
  }

  render() {
    const { isAuthenticated } = this.props.auth

    return (
      <div className="App">
        <main className="mainContent">
          <Switch>
            <Route exact path="/" render={() => (
              isAuthenticated ? (
                <Redirect to="/dashboard" />
              ) : (
                <Redirect to="/site" />
              )
            )} />
            <Route path="/site" component={Site} />
            <AuthRoute path="/dashboard" component={Dashboard} redirectTo="/login" authenticated={isAuthenticated} />
            <AuthRoute path="/hpc_tracker" component={HpcTracker} redirectTo="/login" authenticated={isAuthenticated} />
            <AuthRoute path="/token_authority" component={TokenAuthority} redirectTo="/login" authenticated={isAuthenticated} />
            <AuthRoute path="/storage_tracker" component={StorageTracker} redirectTo="/login" authenticated={isAuthenticated} />
            <UnauthRoute path="/login" component={Login} redirectTo="/dashboard" authenticated={isAuthenticated} />
          </Switch>
        </main>
      </div>
    )
  }
}

export default App
