import HpcTrackerAPIUtil from 'util/hpc_tracker/hpc_tracker_api_util'
import qs from 'qs'

export const fetchTransactions = filter => {
  
  const params = {
    from_date: filter.fromDate,
    to_date: filter.toDate,
    filter: {
      department: filter.departments,
      project: filter.projects,
      service: filter.service,
      service_user: filter.serviceUsers
    }
  }

  const queryString = qs.stringify(params, {
    arrayFormat: "brackets",
    encode: false
  });
  // debugger

  return(
    HpcTrackerAPIUtil.get(`/transactions.json?${queryString}`)
  )
}

export const fetchTransaction = id => (
  HpcTrackerAPIUtil.get(`/transactions/${id}.json`)
)