import * as ProjectAPIUtil from 'util/hpc_tracker/project_api_util';

export const RECEIVE_PROJECTS = 'RECEIVE_PROJECTS';
export const RECEIVE_PROJECT = 'RECEIVE_PROJECT';

// Sync actions
export const receiveProjects = projects => ({
  type: RECEIVE_PROJECTS,
  projects
});

export const receiveProject = project => ({
  type: RECEIVE_PROJECT,
  project
});

// Async actions
export const fetchProjects = () => dispatch => (
  ProjectAPIUtil.fetchProjects()
    .then(projects => dispatch(receiveProjects(projects)))
);

export const fetchProject = id => dispatch => (
  ProjectAPIUtil.fetchProject(id)
    .then(project => dispatch(receiveProject(project)))
);