import * as ApplicationAPIUtil from 'util/token_authority/application_api_util'

export const RECEIVE_APPLICATIONS = 'RECEIVE_APPLICATIONS'
export const RECEIVE_APPLICATION = 'RECEIVE_APPLICATION'

export const receiveApplication = application => ({
  type: RECEIVE_APPLICATION,
  application
})

export const receiveApplications = applications => ({
  type: RECEIVE_APPLICATIONS,
  applications
})

export const fetchApplication = id => dispatch => (
  ApplicationAPIUtil.fetchApplication(id)
    .then(application => dispatch(receiveApplication(application)))
)

export const fetchApplications = () => dispatch => (
  ApplicationAPIUtil.fetchApplications()
    .then(applications => dispatch(receiveApplications(applications)))
)

export const createApplication = (name, description) => dispatch => (
  ApplicationAPIUtil.createApplication(name, description)
    .then(() => dispatch(fetchApplications()))
)

export const deleteApplication = (id) => dispatch => (
  ApplicationAPIUtil.deleteApplication(id)
    .then(() => dispatch(fetchApplications()))
)