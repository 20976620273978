// @flow

import React from 'react';
import ReactDOM from 'react-dom';
import configureStore from 'store/store';

import Root from 'components/root/root'

// Import main SCSS file
import './assets/scss/main.scss';

document.addEventListener('DOMContentLoaded', () => {
  const store = configureStore({});

  //dev only -- remove for production
  console.log("Remember to remove window.store for build!")
  window.store = store;
  // end dev only

  const root = document.getElementById('root')
  ReactDOM.render(<Root store={store} />, root)
})
