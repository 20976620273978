import React from 'react'
import { Button, Card } from 'react-bootstrap'
import { DEVELOP_WIKI_LINK, SCU_MAILTO_LINK } from 'util/site/external_links'

export default class Develop extends React.PureComponent {
  render() {
    return(
      <Card.Body>
        <Card.Title className="my-4">Development consulting and resources for software developers.</Card.Title>
        <Card.Text>
          Our team of software engineers consults and assists with your software development needs. We provide on-demand training and consulting, as well as custom software development services.
        </Card.Text>
        <Card.Text>
          We also provide resources to assist your own development efforts. We offer source control and continuous integration via our private GitLab server. We also publish best practice guides on various development frameworks.
        </Card.Text>
        <Card.Text>
          Please see our list of development services for all details, or <a href={SCU_MAILTO_LINK}>get in touch</a> if you'd like to learn more!
        </Card.Text>
        <Button className="my-4" variant="primary" href={DEVELOP_WIKI_LINK}>Details about Development services</Button>
      </Card.Body>
    )
  }
}
