import { forEach } from 'lodash'

export const generateGroupsTable = (groups) => {
  let data = []
  
  if (groups) {
    forEach(Object.values(groups), group => {
      let entry = {
        groupId: group.id,
        groupName: group.name
      }

      data.push(entry)
    }) 
  }
  
  let tableData = {
    data: data,
    columns: [{
        Header: 'id',
        accessor: 'groupId'
      }, {
        Header: 'Name',
        accessor: 'groupName'
      }
    ]
  }
  return tableData
}