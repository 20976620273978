import { connect } from 'react-redux'

import Users from 'components/token_authority/users/users'

import { fetchUsers } from 'actions/token_authority/users_actions'

const mapStateToProps = state => {
  const { tokenAuthority } = state
  const { users } = tokenAuthority

  return {
    users
  }
}

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(fetchUsers())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Users)