import React from 'react';

import moment from 'moment'
import Select from 'react-select'

import { Button, ButtonGroup, Container, Col, Row, Image } from 'react-bootstrap'

import MonthSelector from 'components/hpc_tracker/month_selector/month_selector'
import TransactionsOverTimeChart from 'components/hpc_tracker/charts/transactions/transactions_over_time_chart';
import TransactionsTreemap from 'components/hpc_tracker/charts/transactions/transactions_treemap';
import TransactionsPiechart from 'components/hpc_tracker/charts/transactions/transactions_piechart.js'
import TransactionList from 'components/hpc_tracker/transactions/transaction_list';

import 'react-select/dist/react-select.css';

import { get } from 'lodash'

// Date has to be stored as a string and converted to/from moment as needed.
// If we store it as a `moment` type in state, we'll run into issues as that
// is not immutable. To help with the conversion, the (internal) date format
// is defined here. This is also the same formate required by the API
const dateFormat = 'YYYY-MM-DD'

class UsageDashboard extends React.Component {
  constructor(props) {
    super(props);

    // Initialize state
    this.state = {
      selectedService: null,
      selectedDepartments: [],
      selectedMonth: moment().startOf('month').format(dateFormat),
      selectedProjects: [],
      selectedServiceUsers: [],
      selectedChart: 'line'
    }
  }

  changeChartType = (chartType) => {
    this.setState({selectedChart: chartType})
  }

  handleSelectedServiceChange = (selection) => {
    this.setState({selectedService: selection})
  }

  handleSelectedDepartmentsChange = (selection) => {
    this.setState({selectedDepartments: selection})
  }

  handleSelectedServiceUsersChange = (selection) => {
    this.setState({selectedServiceUsers: selection})
  }

  handleSelectedProjectsChange = (selection) => {
    this.setState({selectedProjects: selection})
  }

  addMonth = () => {
    this.setState({
      selectedMonth: moment(this.state.selectedMonth).add(1, 'month').format(dateFormat)
    })
  }

  subtractMonth = () => {
    this.setState({
      selectedMonth: moment(this.state.selectedMonth).subtract(1, 'month').format(dateFormat)
    })
  }

  // The filters are passed to the API to get the correct transactions.
  // The API expects the ids, which we have stored as .value
  transactionFilters = () => {
    const { selectedMonth, selectedDepartments, selectedProjects, selectedService, selectedServiceUsers } = this.state
    return {
      service: get(selectedService, 'value', null),
      departments: selectedDepartments.map(k => k.value),
      projects: selectedProjects.map(k => k.value),
      serviceUsers: selectedServiceUsers.map(k => k.value),
      fromDate: moment(selectedMonth).startOf('month').format(dateFormat),
      toDate: moment(selectedMonth).endOf('month').format(dateFormat)
    }
  }

  componentDidMount() {
    this.props.fetchServices();
    this.props.fetchDepartments();
    this.props.fetchServiceUsers();
    this.props.fetchProjects();
    // Clear out whatever is cached
    this.props.clearTransactions();
    // Load transactions without filter initially
    this.props.fetchTransactions(this.transactionFilters());
  }

  componentDidUpdate(prevProps, prevState) {
    // Props: Check if the list of all available services changed,
    // and select the first available service if applicable
    if (prevProps !== this.props) {
      if (prevProps.services !== this.props.services) {
        this.selectDefaultService();
      }
    }

    // State: Guard clause: Early out if none of our selections have changed
    if (prevState === this.state) return

    // Clear existing transactions and fetch new transactions according to selected filters
    this.props.clearTransactions()
    this.props.fetchTransactions(this.transactionFilters());
  }

  // Select first service automatically. This ensures the chart is
  // automatically populated after the services are loaded
  selectDefaultService = () => {
    const { services } = this.props
    const serviceKeys = Object.keys(services)

    if (serviceKeys.length > 0) {
      const firstService = services[serviceKeys[0]]
      this.setState({
        selectedService: {
          label: firstService.name,
          value: firstService.id
        }
      })
    }    
  }

  render() {
    const { departments, services, serviceUsers, projects, transactions } = this.props;
    const { selectedChart, selectedMonth, selectedDepartments, selectedProjects, selectedService, selectedServiceUsers } = this.state

    // For the displayed menu options, <Select> expects an array like this:
    // [{ label: 'what is displayed', value: 'internal value'}, { label: ...}]
    const departmentsSelectOptions = Object.keys(departments)
      .map(k => ({
        label: departments[k].name,
        value: departments[k].id
      }));

    const servicesSelectOptions= Object.keys(services)
      .map(k => ({
        label: services[k].name,
        value: services[k].id
      }))

    const serviceUsersSelectOptions = Object.keys(serviceUsers)
      .map(k => ({
        label: serviceUsers[k].name,
        value: serviceUsers[k].id
      }));

    const projectsSelectOptions = Object.keys(projects)
      .map(k => ({
        label: projects[k].name,
        value: projects[k].id
      }));

    let chart
    
    switch(selectedChart) {
      case 'line':
        chart = (<TransactionsOverTimeChart transactions={transactions} />)
        break;
      case 'treemap':
        chart = (<TransactionsTreemap transactions={transactions} />)
        break;
      case 'pie':
        chart = (<TransactionsPiechart transactions={transactions} />)
        break;
      default:
        chart = (<span>No Chart selected.</span>)
    }

    return(
      <Container fluid>
        {/* TITLE */}
        <Row>
          <Col>
            <h2 className="my-5">Usage chart</h2>
          </Col>
        </Row>
        {/* END TITLE */}
        {/* CHART TYPES */}
        <Row>
          <Col>
            <ButtonGroup className="my-3">
              <Button
                variant="light"
                active={selectedChart === 'line'}
                onClick={() => this.changeChartType('line') }
              >
                {
                  selectedChart === 'line' ?
                    <Image src="/img/charts/chart-icons/line-active.png" style={{ height: '3em' }} />  
                    :
                    <Image src="/img/charts/chart-icons/line.png" style={{ height: '3em' }} />
                }
              </Button>
              <Button
                variant="light"
                active={selectedChart === 'treemap'}
                onClick={() => this.changeChartType('treemap') }
              >
                {
                  selectedChart === 'treemap' ?
                    <Image src="/img/charts/chart-icons/treemap-active.png" style={{ height: '3em' }} />  
                    :
                    <Image src="/img/charts/chart-icons/treemap.png" style={{ height: '3em' }} />
                }
              </Button>
              <Button
                variant="light"
                active={selectedChart === 'pie'}
                onClick={() => this.changeChartType('pie') }
              >
                {
                  selectedChart === 'pie' ?
                    <Image src="/img/charts/chart-icons/pie-active.png" style={{ height: '3em' }} />  
                    :
                    <Image src="/img/charts/chart-icons/pie.png" style={{ height: '3em' }} />
                }
              </Button>          
            </ButtonGroup>
          </Col>
        </Row>
        {/* END CHART TYPES */}
        {/* FILTERS */}
        <Row>
          <Col>
            <Select
              name='service-select'
              className='my-3'
              placeholder='Services'
              value={selectedService}
              onChange={this.handleSelectedServiceChange}
              options={servicesSelectOptions}
            />
          </Col>
          <Col>
            <Select
              name='department-select'
              className='my-3'
              placeholder='Departments'
              value={selectedDepartments}
              onChange={this.handleSelectedDepartmentsChange}
              options={departmentsSelectOptions}
              multi />
          </Col>
          <Col>
            <Select
              name='project-select'
              className='my-3'
              placeholder='Projects'
              value={selectedProjects}
              onChange={this.handleSelectedProjectsChange}
              options={projectsSelectOptions}
              multi />
          </Col>
          <Col>
            <Select
              name='user-select'
              className='my-3'
              placeholder='Users'
              value={selectedServiceUsers}
              onChange={this.handleSelectedServiceUsersChange}
              options={serviceUsersSelectOptions}
              multi />
          </Col>
          <Col>            
            <MonthSelector
              className='my-3'
              addMonth={this.addMonth}
              subtractMonth={this.subtractMonth}
              selectedMonth={selectedMonth} />
          </Col>
        </Row>
        {/* END FILTERS */}
        {/* CHART */}
        <Row>
          <Col style={{height: "600px"}}>
            {chart}
          </Col>
        </Row>
        {/* END CHART */}
        {/* TRANSACTIONS TABLE */}
        <Row>
          <Col>
            <h2 className="my-5">All Transactions</h2>
            <TransactionList transactions={transactions} />
          </Col>
        </Row>
        {/* END TRANSACTIONS TABLE */}
      </Container>

    );
  }
}

export default UsageDashboard;
