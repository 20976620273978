// @flow

import { combineReducers } from 'redux'

import authReducer from 'reducers/auth_reducer'
import hpcTrackerRootReducer from 'reducers/hpc_tracker/root_reducer'
import storageTrackerRootReducer from 'reducers/storage_tracker/root_reducer'
import tokenAuthorityRootReducer from 'reducers/token_authority/root_reducer'
import pageReducer from 'reducers/page_reducer'


const rootReducer = combineReducers({
  auth: authReducer,
  pages: pageReducer,
  hpcTracker: hpcTrackerRootReducer,
  storageTracker: storageTrackerRootReducer,
  tokenAuthority: tokenAuthorityRootReducer
});

export default rootReducer;