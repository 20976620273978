import { combineReducers } from 'redux'

import filesetsReducer from 'reducers/storage_tracker/filesets_reducer'
import filesystemsReducer from 'reducers/storage_tracker/filesystems_reducer'
import userStorageStatementsReducer from 'reducers/storage_tracker/user_storage_statements_reducer'
import storageQuotaReducer from 'reducers/storage_tracker/storage_quota_reducer'
import filesetStorageStatementsReducer from 'reducers/storage_tracker/fileset_storage_statements_reducer'

const storageTrackerRootReducer = combineReducers({
  filesets: filesetsReducer,
  filesetStorageStatements: filesetStorageStatementsReducer,
  filesystems: filesystemsReducer,
  userStorageStatements: userStorageStatementsReducer,
  storageQuota: storageQuotaReducer
})

export default storageTrackerRootReducer