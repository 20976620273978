import * as ServiceUserAPIUtil from 'util/hpc_tracker/service_user_api_util';

export const RECEIVE_SERVICE_USERS = 'RECEIVE_SERVICE_USERS';
export const RECEIVE_SERVICE_USER = 'RECEIVE_SERVICE_USER';

// Sync actions
export const receiveServiceUsers = serviceUsers => ({
  type: RECEIVE_SERVICE_USERS,
  serviceUsers
});

export const receiveServiceUser = serviceUser => ({
  type: RECEIVE_SERVICE_USER,
  serviceUser
});

// Async actions
export const fetchServiceUsers = () => dispatch => (
  ServiceUserAPIUtil.fetchServiceUsers()
    .then(serviceUsers => dispatch(receiveServiceUsers(serviceUsers)))
);

export const fetchServiceUser = id => dispatch => (
  ServiceUserAPIUtil.fetchServiceUser(id)
    .then(serviceUser => dispatch(receiveServiceUser(serviceUser)))
);