import { forEach, orderBy, sumBy } from 'lodash'
import { uniqueKeys, transactionsForUser, grandTotal } from 'components/hpc_tracker/charts/transactions/transactions_chart_helpers';
import { transactionsForDepartment, transactionsForProject } from '../../../components/hpc_tracker/charts/transactions/transactions_chart_helpers';

const allUserPiechartData = (transactions) => {
  let data = []

  const users = uniqueKeys(transactions, 'serviceUser.name')

  forEach(users, (userName) => {
    const userTransactions = transactionsForUser(transactions, userName)
    data.push({
      id: userName,
      label: userName, // For tooltip
      value: grandTotal(userTransactions)
    })      
  })

  return data 
}

const allDepartmentsPiechartData = (transactions) => {
  let data = []

  const departments = uniqueKeys(transactions, 'department.name')

  forEach(departments, (departmentName) => {
    const deptTransactions = transactionsForDepartment(transactions, departmentName)
    data.push({
      id: departmentName,
      label: departmentName,
      value: grandTotal(deptTransactions)
    })
  })

  return data
}

const allProjectsPiechartData = (transactions) => {
  let data = []

  const projects = uniqueKeys(transactions, 'project.name')

  forEach(projects, (projectName) => {
    const projectTransactions = transactionsForProject(transactions, projectName)
    data.push({
      id: projectName,
      label: projectName,
      value: grandTotal(projectTransactions)
    })
  })

  return data
}

const topData = (data) => {
  let topData = []
  let grandTotal = sumBy(data, 'value')
  let leftToAssign = grandTotal
  // these are the wcm colors 25% lighter for label legibility
  let colors = ["#f35b5b", "#ff8560", "#ffb762", "#ffff6c", "#ffffc1"]
  
  // Escape if we don't have good data
  if (data.length === 0 || grandTotal <= 0) return []

  forEach(data, (datum) => {
    // We will aggregate all remaining data when one of the following conditions is true:
    // 1. We have 5 individual users; 2. We have assigned 90% of the grand total;
    // 3. The remaining individual users have less than 2% of usage each (the check here
    // exploits the fact that we sorted data before).
    // If any of those conditions apply, aggregate into the 'all other' datum and exit loop
    if (topData.length >= 5 || (leftToAssign / grandTotal) < 0.1 || (datum.value / grandTotal) < 0.02) {
      topData.push({
        id: "other",
        label: "All other",
        value: leftToAssign,
        color: '#ccc'
      })
      return false // Finish loop
    } else {
      topData.push({
        id: datum.id,
        label: datum.label,
        value: datum.value,
        color: colors[topData.length]
      })
      leftToAssign -= datum.value
    }
  })

  return topData
} 

export const topUserPiechartData = (transactions) => {
  let userData = orderBy(allUserPiechartData(transactions), ['value'], ['desc']) 
  return topData(userData)
}

export const topProjectPiechartData = (transactions) => {
  let projectData = orderBy(allProjectsPiechartData(transactions), ['value'], ['desc']) 
  return topData(projectData)
}

export const topDepartmentPiechartData = (transactions) => {
  let deptData = orderBy(allDepartmentsPiechartData(transactions), ['value'], ['desc']) 
  return topData(deptData)
}