import React from 'react'

export default class ApplicationDetail extends React.Component {

  componentDidMount() { 
    const { applicationId } = this.props
    this.props.fetchApplication(applicationId)
  }

  render() {
    const { application } = this.props

    return(
      <div>
        <h4>
          Application Details: { application.name }
        </h4>
        <ul>
          <li>Id: { application.id }</li>
          <li>Description: { application.description }</li>
        </ul>
      </div>
    )
  }
}