import { combineReducers } from 'redux';

import departmentReducer from 'reducers/hpc_tracker/department_reducer';
import projectReducer from 'reducers/hpc_tracker/project_reducer';
import reportReducer from 'reducers/hpc_tracker/report_reducer';
import serviceReducer from 'reducers/hpc_tracker/service_reducer';
import serviceUserReducer from 'reducers/hpc_tracker/service_user_reducer';
import transactionReducer from 'reducers/hpc_tracker/transaction_reducer';

const hpcTrackerRootReducer = combineReducers({
  departments: departmentReducer,
  projects: projectReducer,
  reports: reportReducer,
  services: serviceReducer,
  serviceUsers: serviceUserReducer,
  transactions: transactionReducer
});

export default hpcTrackerRootReducer;