import * as StorageQuotumAPIUtil from 'util/storage_tracker/storage_quotum_api_util'

export const CLEAR_STORAGE_QUOTA = 'CLEAR_STORAGE_QUOTA'
export const RECEIVE_STORAGE_QUOTA = 'RECEIVE_STORAGE_QUOTA'

// Sync actions
export const clearStorageQuota = () => ({
  type: CLEAR_STORAGE_QUOTA
})

export const receiveStorageQuota = storageQuota => ({
  type: RECEIVE_STORAGE_QUOTA,
  storageQuota
})

// Async actions
export const fetchStorageQuota = (filesetId) => dispatch => (
  StorageQuotumAPIUtil.fetchStorageQuota(filesetId)
    .then(storageQuota => dispatch (receiveStorageQuota(storageQuota)))
)