import { connect } from 'react-redux'

import NewApplicationForm from 'components/token_authority/applications/new_application_form'

import { createApplication } from 'actions/token_authority/applications_actions'

const mapStateToProps = state => {
  const { tokenAuthority } = state
  const { applications } = tokenAuthority

  return {
    applications
  }
}

const mapDispatchToProps = dispatch => ({
  createApplication: (name, description) => dispatch(createApplication(name, description))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewApplicationForm)