import React from 'react'

import { Button, Carousel, Col, Row } from 'react-bootstrap'

export default class SitePublications extends React.PureComponent {
  render() {
    return(
      <Row className="justify-content-md-center text-center my-5">
        <Col md={8}>
          <h1 className="my-5">SCU supported research.</h1>
          <p className="my-3">
            We provide access to high-performance computing resources for research, and we support researchers in using these resources. The following is a selection of papers utilizing our resources:
          </p>
          <Carousel className="my-3">
            <Carousel.Item>
              <img
                className="d-block w-100"
                width="800"
                height="400"
                alt="First slide"
                src="img/slides/international_space_station.png"
              />  
              <Carousel.Caption className="carouselCaptionBlackMask">
                <h4>The NASA Twins Study: A multi-dimensional analysis of a year-long human spaceflight</h4>
                <p>How microgravity and other spaceflight-related environmental factors influence chemical changes in RNA and DNA.</p>
                <Button href="https://science.sciencemag.org/content/364/6436/eaau8650" target="_blank" className="my-2" variant="dark">Article in Science</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                width="800"
                height="400"
                alt="First slide"
                src="img/slides/network.png"
                href="http://vivo.med.cornell.edu/display/pubid85018701942"
              />  
              <Carousel.Caption className="carouselCaptionBlackMask">
                <h4>An integrative computational approach for prioritization of genomic variants</h4>
                <p>Seamless integration of bioinformatics resources enables fast and efficient prioritization and characterization of genomic factors and molecular networks contributing to the phenotypes of interest.</p>
                <Button href="http://vivo.med.cornell.edu/display/pubid84918588740" target="_blank" className="my-2"  variant="dark">Details on VIVO</Button>
              </Carousel.Caption>
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                width="800"
                height="400"
                alt="First slide"
                src="img/slides/purple_test_tubes.png"
              />  
              <Carousel.Caption className="carouselCaptionBlackMask">
                <h4>Geospatial Resolution of Human and Bacterial Diversity with City-Scale Metagenomics</h4>
                <p>Creating a baseline metagenomic map of New York City by sequencing DNA from surfaces across the entire subway system, the Gowanus Canal, and public parks.</p>
                <Button href="http://vivo.med.cornell.edu/display/pubid84925104897" target="_blank" className="my-2"  variant="dark">Details on VIVO</Button>
              </Carousel.Caption>
            </Carousel.Item>
          </Carousel>
          <Button href="https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/73695233/SCU+supported+research" target="_blank" className="my-3">See more publications</Button>
        </Col>
      </Row>
    )
  }
}