import * as DepartmentAPIUtil from 'util/hpc_tracker/department_api_util';

export const RECEIVE_DEPARTMENTS = 'RECEIVE_DEPARMENTS';
export const RECEIVE_DEPARTMENT = 'RECEIVE_DEPARTMENT';

// Sync actions
export const receiveDepartments = departments => ({
  type: RECEIVE_DEPARTMENTS,
  departments
});

export const receiveDepartment = department => ({
  type: RECEIVE_DEPARTMENT,
  department
})

// Async actions
export const fetchDepartments = () => dispatch => (
  DepartmentAPIUtil.fetchDepartments()
    .then(departments => dispatch(receiveDepartments(departments)))
);

export const fetchDepartment = id => dispatch => (
  DepartmentAPIUtil.fetchDepartment(id)
    .then(department => dispatch(receiveDepartment(department)))
);
