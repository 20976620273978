import React from 'react'

import { Collapse, Col, ListGroup, Row } from 'react-bootstrap'
import { SERVICES_WIKI_LINK, WIKI_LINK, SCU_MAILTO_LINK, SCU_ACCOUNT_WIKI_LINK, SCU_SERVICE_REQUEST_LINK, SCU_ACCOUNT_REQUEST_LINK, SCU_PASSWORD_RESET_LINK } from 'util/site/external_links' 

const FAQ_ACCOUNT = "FAQ_ACCOUNT"
const FAQ_SERVICES = "FAQ_SERVICES"

export default class SiteFaq extends React.PureComponent {
  constructor(props, context) {
    super(props, context)

    this.state = {
      open: null
    }
  }

  isOpen = key => (this.state.open === key)

  // Toggle the open state 
  toggleOpen = key => {
    if (this.state.open === key) {
      this.setState({ open: null })
    } else {
      this.setState({ open: key })
    }
  }

  render() {
    return(
      <>
        <Row className="justify-content-md-center my-5">
          <Col md={8}>
            <h1 className="text-center my-5">Get in touch.</h1>
            <p className="text-center my-5">
              We are here to help solve your problem. Please select from these options to find the best way to contact us:
            </p>
          </Col>
        </Row>
        <Row className="justify-content-md-center my-5">
          <Col md={7} lg={5}>
            <ListGroup className="site-faq-list text-center">
              <ListGroup.Item 
                onClick={() => this.toggleOpen(FAQ_ACCOUNT)}
                aria-controls={FAQ_ACCOUNT}
                aria-expanded={this.isOpen(FAQ_ACCOUNT)}
                active={this.isOpen(FAQ_ACCOUNT)}
                action
              >
                I have questions about my <strong>SCU Account</strong>.
              </ListGroup.Item>
              <Collapse in={this.isOpen(FAQ_ACCOUNT)}>
                <ListGroup id={FAQ_ACCOUNT} className="text-center px-3" variant="flush">
                  <ListGroup.Item action href={SCU_ACCOUNT_WIKI_LINK}>What is the 'SCU Account'?</ListGroup.Item>
                  <ListGroup.Item action href={SCU_ACCOUNT_REQUEST_LINK}>I'd like to request a new SCU Account.</ListGroup.Item>
                  <ListGroup.Item action href={SCU_PASSWORD_RESET_LINK}>I need to reset my password.</ListGroup.Item>
                  <ListGroup.Item action href={SCU_MAILTO_LINK}>I have other questions.</ListGroup.Item>
                </ListGroup>
              </Collapse>

              <ListGroup.Item
                onClick={() => this.toggleOpen(FAQ_SERVICES)}
                aria-controls={FAQ_SERVICES}
                aria-expanded={this.isOpen(FAQ_SERVICES)}    
                active={this.isOpen(FAQ_SERVICES)}
                action        
              >
                I'd like to request <strong>SCU Services</strong>.
              </ListGroup.Item>
              <Collapse in={this.isOpen(FAQ_SERVICES)}>
                <ListGroup id={FAQ_SERVICES} className="text-center px-3" variant="flush">
                  <ListGroup.Item action href={SERVICES_WIKI_LINK}>What are the details and the prices for SCU services?</ListGroup.Item>
                  <ListGroup.Item action href={SCU_SERVICE_REQUEST_LINK}>I'd like to submit a service request.</ListGroup.Item>
                  <ListGroup.Item action href={SCU_MAILTO_LINK}>I have other questions.</ListGroup.Item>
                </ListGroup>
              </Collapse>       

              <ListGroup.Item action href={WIKI_LINK}>
                I want to visit the <strong>SCU Wiki</strong>.
              </ListGroup.Item>

              <ListGroup.Item action href={SCU_MAILTO_LINK}>
                I have <strong>other questions</strong>.
              </ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </>
    )
  }
}
