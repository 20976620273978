import React from 'react'
import Table from 'components/tables/table'
import { generateApplicationsTable } from 'util/table_helpers/applications_table_helpers.js'

export default class ApplicationsTable extends React.PureComponent {
  render() {
    const tableData = generateApplicationsTable(this.props.applications)

    // const tableData = {
    //   data: [
    //     {
    //       id: 1,
    //       name: 'appname1'
    //     }, {
    //       id: 2,
    //       name: 'appname2'
    //     }
    //   ],
    //   columns: [
    //     {
    //       Header: 'ID',
    //       accessor: 'id'
    //     }, {
    //       Header: 'Name',
    //       accessor: 'name'
    //     }
    //   ],
    //   subcomponent: (row => {
    //     return (
    //       <div>Subcomponent!</div>
    //     )
    //   })
    // }

    return (<Table tableData={tableData} />)
  }
}