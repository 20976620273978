import { connect } from 'react-redux'

import CurrentUser from 'components/token_authority/users/current_user'

import { fetchCurrentUser } from 'actions/token_authority/users_actions'
import { selectCurrentUser } from 'selectors/token_authority/user_selectors'

const mapStateToProps = state => {
  const { tokenAuthority } = state
  const { users } = tokenAuthority
  const currentUser = selectCurrentUser(tokenAuthority)

  return {
    currentUser,
    users
  }
}

const mapDispatchToProps = dispatch => ({
  fetchCurrentUser: () => dispatch(fetchCurrentUser())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CurrentUser)