import { connect } from 'react-redux'

import DashboardNav from 'components/dashboard/dashboard_nav'

const mapStateToProps = state => {
  const { auth: { jwt } = {} } = state
  return {
    userToken: jwt
  }
}

const mapDispatchToProps = dispatch => (
  {}
)

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardNav)
