import { RECEIVE_FILESYSTEM, RECEIVE_FILESYSTEMS } from 'actions/storage_tracker/filesystems_actions'
import merge from 'lodash/merge'

const filesystemsReducer = ( state={}, action ) => {
  Object.freeze(state)
  
  switch(action.type) {
    case RECEIVE_FILESYSTEMS:
      return action.filesystems;
    case RECEIVE_FILESYSTEM:
      const newFilesystem = { [action.filesystem.id] : action.filesystem }
      return merge({}, state, newFilesystem)
    default:
      return state;
  }
}

export default filesystemsReducer