import React from 'react'

import UserDetails from 'components/token_authority/users/user_details'

import { Container, Col, Row } from 'react-bootstrap'

export default class CurrentUser extends React.Component {
  componentDidMount() {
    this.props.fetchCurrentUser()
  }

  render() {
    return (
      <Container fluid>
        <Row>
          <Col>
            <h2 className="my-5">Your Account</h2>
          </Col>
        </Row>
        <UserDetails user={this.props.currentUser} />
      </Container>
    )
  }
}
