import React from 'react'

import { Button } from 'react-bootstrap'

export default class Logout extends React.PureComponent {
  render() {
    return (
      <Button variant="secondary" onClick={(e) => this.handleLogoutClick(e)}>
        Logout
      </Button>
    )
  }

  handleLogoutClick(event) {
    const { history } = this.props
    this.props.logout()
    history.push("/")
  }
}
