import { connect } from 'react-redux'

import { fetchApplication } from 'actions/token_authority/applications_actions'
import { selectApplication } from 'selectors/token_authority/application_selectors'

import ApplicationDetail from 'components/token_authority/applications/application_detail'

const mapStateToProps = (state, { match }) => {
  const { tokenAuthority } = state
  const applicationId = match.params.applicationId
  const application = selectApplication(tokenAuthority, applicationId)

  return {
    application,
    applicationId
  }
}

const mapDispatchToProps = dispatch => ({
  fetchApplication: id => dispatch(fetchApplication(id))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ApplicationDetail)