import { connect } from 'react-redux'
import { loginUser } from 'actions/auth_actions'
import { withRouter } from 'react-router-dom'
import Login from 'components/auth/login'

const mapStateToProps = (state) => {
  const { auth } = state
  return {
    auth
  }
}

const mapDispatchToProps = dispatch => ({
  login: credentials => dispatch(loginUser(credentials))
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Login))