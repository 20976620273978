import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { logoutUser } from 'actions/auth_actions'
import Logout from 'components/auth/logout'

const mapStateToProps = (state) => ({
})

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logoutUser())
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Logout))
