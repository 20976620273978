import * as FilesystemAPIUtil from 'util/storage_tracker/filesystem_api_util'

export const RECEIVE_FILESYSTEMS = 'RECEIVE_FILESYSTEMS';
export const RECEIVE_FILESYSTEM = 'RECEIVE_FILESYSTEM';

// Sync actions
export const receiveFilesystems = filesystems => ({
  type: RECEIVE_FILESYSTEMS,
  filesystems
})

export const receiveFilesystem = filesystem => ({
  type: RECEIVE_FILESYSTEM,
  filesystem
})

// Async actions
export const fetchFilesystems = () => dispatch => (
  FilesystemAPIUtil.fetchFilesystems()
    .then(filesystems => dispatch(receiveFilesystems(filesystems)))
)

export const fetchFilesystem = id => dispatch => (
  FilesystemAPIUtil.fetchFilesystem(id)
    .then(filesystem => dispatch(receiveFilesystem(filesystem)))
)