import { forEach } from 'lodash'

export const generateUsersTable = (users) => {
  let data = []
  
  if (users) {
    forEach(Object.values(users), user => {
      let entry = {
        userId: user.id,
        userName: user.name
      }

      data.push(entry)
    }) 
  }
  
  let tableData = {
    data: data,
    columns: [{
        Header: 'id',
        accessor: 'userId'
      }, {
        Header: 'Name',
        accessor: 'userName'
      }
    ]
  }
  return tableData
}