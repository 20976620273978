import * as TransactionAPIUtil from 'util/hpc_tracker/transaction_api_util';

export const CLEAR_TRANSACTIONS = 'CLEAR_TRANSACTIONS'
export const RECEIVE_TRANSACTIONS = 'RECEIVE_TRANSACTIONS';
export const RECEIVE_TRANSACTION = 'RECEIVE_TRANSACTION';

// Sync actions
export const clearTransactions= () => ({
  type: CLEAR_TRANSACTIONS
})

export const receiveTransactions = transactions => ({
  type: RECEIVE_TRANSACTIONS,
  transactions
});

export const receiveTransaction = transaction => ({
  type: RECEIVE_TRANSACTION,
  transaction
});

// Async actions
export const fetchTransactions = filter => dispatch => (
  TransactionAPIUtil.fetchTransactions(filter)
    .then(transactions => dispatch(receiveTransactions(transactions)))
);

export const fetchTransaction = id => dispatch => (
  TransactionAPIUtil.fetchTransaction(id)
    .then(transaction => dispatch(receiveTransaction(transaction)))
);