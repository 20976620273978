import React from 'react'
import { LinkContainer } from "react-router-bootstrap";
import { Row, Col, ListGroup } from 'react-bootstrap'

import { SCU_MAILTO_LINK, WIKI_LINK } from 'util/site/external_links'

export default class InternalFooter extends React.PureComponent {
  render() {
    return(
      <>
        <hr className="my-5" />
        <Row>
          <Col>
            <ListGroup className="my-5 justify-content-center list-group-horizontal-md site-footer">
              <ListGroup.Item>&copy; Scientific Computing Group at Weill Cornell Medicine.</ListGroup.Item>
              <ListGroup.Item>
                <LinkContainer to="/site">
                  <a href="/site">SCU Website</a>
                </LinkContainer>
              </ListGroup.Item>
              <ListGroup.Item><a href={WIKI_LINK}>SCU Wiki</a></ListGroup.Item>
              <ListGroup.Item><a href={SCU_MAILTO_LINK}>scu@med.cornell.edu</a></ListGroup.Item>
            </ListGroup>
          </Col>
        </Row>
      </>
    )
  }
}
