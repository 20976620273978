import { connect } from 'react-redux'

import Dashboard from 'components/dashboard/dashboard'

// This currently doesn't do anyhing since we refactored the routing -
// before, it would use this container to connect `match` and `withRouter`.
// Keeping this in place since in the future, we will have to fetch a list
// of applications from some API to allow users to rearrange their dashboards
// etc.

const mapStateToProps = (state) => {
  return {}
}

const mapDispatchToProps = dispatch => ({
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Dashboard)