import React from 'react'
import { Button, Card, Table } from 'react-bootstrap'
import { COMPUTE_WIKI_LINK, SCU_MAILTO_LINK } from 'util/site/external_links'

export default class Compute extends React.PureComponent {
  render() {
    return(
      <Card.Body>
        <Card.Title className="my-4">On-premises high performance computing.</Card.Title>
        <Card.Text>
          We maintain multiple clusters with different configurations to serve the varied needs of our diverse user base:
        </Card.Text>       
        <Table responsive striped hover className="my-4">
          <thead>
            <tr>
              <th></th>
              <th>Molecular Dynamics</th>
              <th>Research Genomics</th>
              <th>cryoEM</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Cores</td>
              <td>1280</td>
              <td>2000+</td>
              <td>420</td>
            </tr>
            <tr>
              <td>Nodes</td>
              <td>80</td>
              <td>59</td>
              <td>18</td>
            </tr>
            <tr>
              <td>GPU</td>
              <td>-</td>
              <td>-</td>
              <td>Tesla P100</td>
            </tr>
            <tr>
              <td>Scheduler</td>
              <td>Slurm</td>
              <td>SGE</td>
              <td>Slurm</td>
            </tr>
          </tbody>
        </Table>
        <Card.Text>
          All clusters are connected to on-premises storage via Infiniband interconnect to allow fast and reliable access to your data.
          We can also assist with designing custom private clusters for your team. Please see our list of computational services for all details, or <a href={SCU_MAILTO_LINK}>get in touch</a> if you'd like to learn more!
        </Card.Text>
        <Button className="my-4" variant="primary" href={COMPUTE_WIKI_LINK}>Details about computational services</Button>
      </Card.Body>
    )
  }
}
