import { LOGIN_REQUEST, LOGIN_SUCCESS, LOGIN_FAILURE, LOGOUT_SUCCESS } from 'actions/auth_actions'
import merge from 'lodash/merge'

const initialState = {
  isFetching: false,
  isAuthenticated: sessionStorage.getItem('jwt') ? true : false,
  jwt: sessionStorage.getItem('jwt'),
  userName: sessionStorage.getItem('userName'),
  errorMessage: null
}

const authReducer = (state = initialState, action) => {
  Object.freeze(state);
  switch(action.type) {
    case LOGIN_REQUEST:
      return merge({}, state, {
        isFetching: true,
        isAuthenticated: false,
        errorMessage: null,
        credentials: action.credentials
      }) 
    case LOGIN_SUCCESS:
      return merge({}, state, {
        isFetching: false,
        isAuthenticated: true,
        errorMessage: null,
        jwt: action.jwt,
        userName: action.userName
      })
    case LOGIN_FAILURE:
      return merge({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: action.message
      })
    case LOGOUT_SUCCESS:
      return merge({}, state, {
        isFetching: false,
        isAuthenticated: false,
        errorMessage: null,
        jwt: null,
        userName: null
      })
    default:
      return state;
  }
}

export default authReducer;