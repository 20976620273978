import { RECEIVE_USER, RECEIVE_USERS, RECEIVE_CURRENT_USER_ID } from 'actions/token_authority/users_actions'
import merge from 'lodash/merge'

const usersReducer = ( state={}, action ) => {
  Object.freeze(state)
  
  switch(action.type) {
    case RECEIVE_USERS:
      return action.users
    case RECEIVE_CURRENT_USER_ID:
    const receivedUserId = { currentUserId: action.id }
    return merge({}, state, receivedUserId)
    case RECEIVE_USER:
      const receivedUser = { [action.user.id] : action.user }
      return merge({}, state, receivedUser)
    default:
      return state
  }
}

export default usersReducer