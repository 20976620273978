import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { LinkContainer } from "react-router-bootstrap";
import { Button, Card } from 'react-bootstrap'

export default class DashboardNavIcon extends React.PureComponent {
  render() {
    const { link, text, title, icon } = this.props
    return(
      <Card className="text-center">
        <Card.Header>
          <Card.Title><FontAwesomeIcon icon={icon} /> {title}</Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Text>{text}</Card.Text>
        </Card.Body>
        <Card.Footer>
          <LinkContainer to={link}>
            <Button>Open Application</Button>
          </LinkContainer>
        </Card.Footer>
      </Card>
    )
  }
}
