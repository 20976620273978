import * as StorageUsageStatementsAPIUtil from 'util/storage_tracker/storage_usage_statements_api_util'

export const RECEIVE_USER_STORAGE_STATEMENTS = 'RECEIVE_USER_STORAGE_STATEMENTS'
export const RECEIVE_USER_STORAGE_STATEMENT = 'RECEIVE_USER_STORAGE_STATEMENT'
export const RECEIVE_FILESET_STORAGE_STATEMENTS = 'RECEIVE_FILESET_STORAGE_STATEMENTS'

export const receiveStorageUsageStatements = storageUsageStatements => ({
  type: RECEIVE_USER_STORAGE_STATEMENTS,
  storageUsageStatements
})

export const receiveStorageUsageStatement = storageUsageStatement => ({
  type: RECEIVE_USER_STORAGE_STATEMENT,
  storageUsageStatement
})

export const receiveFilesetUsageStatements = storageUsageStatements => ({
  type: RECEIVE_FILESET_STORAGE_STATEMENTS,
  storageUsageStatements
})

export const fetchFilesetUsageStatements = () => dispatch => (
  StorageUsageStatementsAPIUtil.fetchFilesetUsageStatements()
    .then(usageStatements => dispatch(receiveFilesetUsageStatements(usageStatements)))
)

export const fetchUserStorageStatements = () => dispatch => (
  StorageUsageStatementsAPIUtil.fetchUserUsageStatements()
    .then(usageStatements => dispatch(receiveStorageUsageStatements(usageStatements)))
)

export const fetchUserStorageStatement = id => dispatch => (
  StorageUsageStatementsAPIUtil.fetchUserUsageStatement(id)
    .then(usageStatement => dispatch(receiveStorageUsageStatement(usageStatement)))
)