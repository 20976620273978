import _ from 'lodash'

export const allTransactions = ({ transactions }) => {
  return (
  Object.keys(transactions)
    .map(k => transactions[k])
  )
}

export const transactionsForService = ({ transactions }, serviceId) => {
  return (
    _.filter(transactions, (t) => ( t.serviceId === serviceId ))
  )
}
