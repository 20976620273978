import React from 'react'
import Table from 'components/tables/table'

import { generateFilesetStorageStatementsTableData, generateUserStorageStatementsTableData } from 'util/table_helpers/storage_table_helpers'
import { Container, Col, Row } from 'react-bootstrap'

export default class StorageStatements extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loadingFilesetStatements: true,
      loadingUserStatements: true
    }
  }

  componentDidMount() {
    this.props.fetchFilesetUsageStatements()
      .then(() => {this.setState({ loadingFilesetStatements: false }) })
    this.props.fetchUserStorageStatements()
      .then(() => {this.setState({ loadingUserStatements: false }) })
  }

  render() {
    const { filesetStorageStatements, userStorageStatements } = this.props
    const { loadingFilesetStatements, loadingUserStatements } = this.state

    const userTableData = generateUserStorageStatementsTableData(userStorageStatements) || {}
    const filesetTableData = generateFilesetStorageStatementsTableData(filesetStorageStatements) || {}

    return(
      <Container fluid>
        <Row>
          <Col>
            <h2 className="my-3">Statements</h2>
            <p className="my-3">Storage reports are generated at the beginning of every month. All reports are usually available on the 2nd of every month.</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="my-3">User statements</h3>
            <Table tableData={userTableData} loading={loadingUserStatements} />
          </Col>
        </Row>
        <Row>
          <Col>
            <h3 className="my-3">Fileset statements</h3>
            <Table tableData={filesetTableData} loading={loadingFilesetStatements} />
          </Col>
        </Row>
      </Container>
    )
  }
}