import React from 'react'

import DashboardNav from 'components/dashboard/dashboard_nav_container'
import InternalNavbar from 'components/internal_navbar/internal_navbar_container'
import InternalFooter from 'components/internal_footer/internal_footer'

export default class Dashboard extends React.Component {
  render() {
    return(
      <>
        <InternalNavbar secondaryNav={{ title: "Dashboard" }}/>
        <DashboardNav />
        <InternalFooter />
      </>
    )
  }
}
