import * as UserAPIUtil from 'util/token_authority/user_api_util'

export const RECEIVE_USERS = 'RECEIVE_USERS'
export const RECEIVE_USER = 'RECEIVE_USER'
export const RECEIVE_CURRENT_USER_ID = 'RECEIVE_CURRENT_USER_ID'

export const receiveUsers = users => ({
  type: RECEIVE_USERS,
  users
})

export const receiveUser = user => ({
  type: RECEIVE_USER,
  user
})

export const receiveCurrentUserId = id => ({
  type: RECEIVE_CURRENT_USER_ID,
  id
})

export const fetchUsers = () => dispatch => (
  UserAPIUtil.fetchUsers()
    .then(users => dispatch(receiveUsers(users)))
)

// Calls two sync actions - receiveCurrentUserId sets the id for the
// currentUser, while receiveUser sets the data for this user
// The alternative would be to have separate reducer actions for
// `user` and `currentUser`, but that would be problematic if p.e.
// the admin changes their own permissions, since then we'd need to update
// both `user` and `currentUser`, which would add a lot of avoidable logic
export const fetchCurrentUser = () => dispatch => (
  UserAPIUtil.fetchCurrentUser()
    .then(user => {
      dispatch(receiveCurrentUserId(user.id))
      dispatch(receiveUser(user))
    })
)