import React from 'react'
import { ResponsiveTreeMap } from '@nivo/treemap'

export default class Treemap extends React.PureComponent {
  render() {
    const { treemapData, onClick } = this.props

    const tooltipFunction = d => {
      if (d.data.tooltip) {
        return d.data.tooltip
      } else if (d.parent && d.parent.data.tooltip) {
        return d.parent.data.tooltip
      }
      return 'Hover over fileset for details'
    }

    return(
      <ResponsiveTreeMap
        root={treemapData}
        tile="binary"
        identity="name"
        tooltip={d => tooltipFunction(d)}
        borderWidth={1}
        borderColor="#000000"
        innerPadding={0}
        outerPadding={6}
        label={d => (d.label || null)}
        labelSkipSize={36}
        orientLabel={false}
        labelTextColor="#000000"
        colors="nivo"
        colorBy={d => (d.color || "depth" )}
        animate={true}
        motionStiffness={90}
        motionDamping={11}
        onClick={onClick}
      />
    )
  }
}