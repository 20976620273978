import * as ReportAPIUtil from 'util/hpc_tracker/report_api_util';

export const RECEIVE_REPORT = 'RECEIVE_REPORT';

// Sync actions
export const receiveReport = report => ({
  type: RECEIVE_REPORT,
  report
});

// Async actions
export const fetchReport = id => dispatch => (
  ReportAPIUtil.fetchReport(id).then(report => dispatch(receiveReport(report)))
);