import React from 'react'
import Select from 'react-select'
import { ResponsivePie } from '@nivo/pie'
import { topDepartmentPiechartData, topProjectPiechartData, topUserPiechartData } from 'util/chart_helpers/hpc_tracker/piechart_helpers'

import { get } from 'lodash'
import { Row, Col } from 'react-bootstrap'

class TransactionsPiechart extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      chartType: "users"
    }
  }

  handleSelectedChartTypeChange = (selection) => {
    this.setState({ chartType: get(selection, 'value') })
  }

  render() {
    const { chartType } = this.state
    const { transactions } = this.props

    const chartTypeSelectionOptions = [
      { label: "Top Users", value: 'users' },
      { label: "Top Departments", value: 'departments' },
      { label: "Top Projects", value: 'projects' },
    ]

    let chartData

    switch(chartType) {
      case 'users':
        chartData = topUserPiechartData(transactions)
      break;
      case 'departments':
        chartData = topDepartmentPiechartData(transactions)
      break;
      case 'projects':
        chartData = topProjectPiechartData(transactions)
      break;
      default:
        chartData=[]
    }

    return(
      <Row>
        <Col md={4} lg={3}>
          <Select
            name='chartType-select'
            className='my-3'
            placeholder='Chart type'
            value={chartType}
            options={chartTypeSelectionOptions}
            onChange={this.handleSelectedChartTypeChange}
          />
        </Col>
        <Col md={8} lg={9} style={{ height: '600px' }}>
          <ResponsivePie
            data={chartData}
            margin={{
                "top": 40,
                "right": 80,
                "bottom": 40,
                "left": 80
            }}
            isInteractive={true} // Enable later
            innerRadius={0.5}
            padAngle={0.7}
            cornerRadius={3}
            colors="nivo"
            colorBy={d => d.color}
            borderWidth={1}
            borderColor="inherit:darker(0.2)"
            sliceLabel={d => `${d.value.toFixed(0)}`}
            radialLabel="label"
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor="#333333"
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={1}
            radialLabelsLinkColor="inherit"
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor="#333333"
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </Col>
      </Row>
    )
  }
}

export default TransactionsPiechart