import { CLEAR_FILESETS, RECEIVE_FILESET, RECEIVE_FILESETS } from 'actions/storage_tracker/filesets_actions'
import merge from 'lodash/merge'

const filesetsReducer = ( state={}, action ) => {
  Object.freeze(state)
  
  switch(action.type) {
    case CLEAR_FILESETS:
      return {};
    case RECEIVE_FILESETS:
      return action.filesets;
    case RECEIVE_FILESET:
      const newFileset = { [action.fileset.id] : action.fileset }
      return merge({}, state, newFileset)
    default:
      return state;
  }
}

export default filesetsReducer