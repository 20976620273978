import { connect } from 'react-redux'

import StorageStatements from 'components/storage_tracker/storage_statements/storage_statements'

import { fetchFilesetUsageStatements, fetchUserStorageStatements } from 'actions/storage_tracker/storage_usage_statements_actions'

const mapStateToProps = state => {
  const { storageTracker } = state
  const { filesetStorageStatements, userStorageStatements } = storageTracker

  return {
    filesetStorageStatements,
    userStorageStatements
  }
}

const mapDispatchToProps = dispatch => ({
  fetchFilesetUsageStatements: () => dispatch(fetchFilesetUsageStatements()),
  fetchUserStorageStatements: () => dispatch(fetchUserStorageStatements())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(StorageStatements)
