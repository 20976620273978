import { RECEIVE_PAGES, RECEIVE_PAGE } from 'actions/page_actions'
import merge from 'lodash/merge'

const pageReducer = (state={}, action) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_PAGES:
      return action.pages;
    case RECEIVE_PAGE:
      const newPage = { [action.page.id]: action.page };
      return merge({}, state, newPage);
    default:
      return state
  }
};

export default pageReducer;