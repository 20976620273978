import { combineReducers } from 'redux'

import applicationsReducer from 'reducers/token_authority/applications_reducer'
import groupsReducer from 'reducers/token_authority/groups_reducer'
import usersReducer from 'reducers/token_authority/users_reducer'

const tokenAuthorityRootReducer = combineReducers({
  applications: applicationsReducer,
  groups: groupsReducer,
  users: usersReducer
})

export default tokenAuthorityRootReducer