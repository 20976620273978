import { connect } from 'react-redux';

import UsageDashboard from 'components/hpc_tracker/dashboards/usage/usage_dashboard'

import { fetchDepartments } from 'actions/hpc_tracker/department_actions';
import { fetchServices } from 'actions/hpc_tracker/service_actions'
import { fetchServiceUsers } from 'actions/hpc_tracker/service_user_actions';
import { fetchProjects } from 'actions/hpc_tracker/project_actions';
import { clearTransactions, fetchTransactions } from 'actions/hpc_tracker/transaction_actions';

import { allTransactions } from 'selectors/hpc_tracker/transaction_selectors';

const mapStateToProps = (state, ownProps) => {
  const { hpcTracker } = state;
  const { departments, services, serviceUsers, projects } = hpcTracker;
  const transactions = allTransactions(hpcTracker);
  
  return {
    departments,
    projects,
    services,
    serviceUsers,
    transactions
  };
}

const mapDispatchToProps = dispatch => ({
  fetchDepartments: () => dispatch(fetchDepartments()),
  fetchProjects: () => dispatch(fetchProjects()),
  fetchServices: () => dispatch(fetchServices()),
  fetchServiceUsers: () => dispatch(fetchServiceUsers()),
  clearTransactions: () => dispatch(clearTransactions()),
  fetchTransactions: filter => dispatch(fetchTransactions(filter))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UsageDashboard);