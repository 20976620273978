import React from 'react'
import moment from 'moment'

class MonthSelector extends React.PureComponent {
  render() {
    const { selectedMonth, addMonth, subtractMonth } = this.props
    return(
      <div className='monthSelector my-3'>
        <span className='clickable' onClick={subtractMonth}>{String.fromCharCode("9668")}</span>
        <span className='monthDisplay'>
          { moment(selectedMonth).format('MMM YYYY') }
        </span>
        <span className='clickable' onClick={addMonth}>{String.fromCharCode("9658")}</span>
      </div>
    )
  }
}

export default MonthSelector