// Central list of external links used throughout the site
// Use in your components as follows:
// import { DEVELOP_WIKI_LINK } from 'util/site/external_links'
// <a href={DEVELOP_WIKI_LINK}>Developer Wiki</a>

export const WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/overview"
export const COMPUTE_WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/720903/Computation"
export const DEVELOP_WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/688145/Software+Development"
export const STORE_WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/753725/Storage"
export const SERVICES_WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/688135/SCU+Resources+and+Services"

export const SCU_MAILTO_LINK = "mailto:scu@med.cornell.edu?body=Please state your question here. If this is related to a system error, please include any error messages you see in as much detail as possible."

export const SCU_ACCOUNT_WIKI_LINK = "https://wcmscu.atlassian.net/wiki/spaces/WIKI/pages/360463/SCU+Account"
export const SCU_ACCOUNT_REQUEST_LINK = "https://wcmcsbx.service-now.com/scp?id=scp_sc_cat_item&sys_id=9c64afa91382ef0037507b104244b098"
export const SCU_PASSWORD_RESET_LINK = "https://scu.med.cornell.edu/sspr"
export const SCU_SERVICE_REQUEST_LINK = "https://wcmc.corefacilities.org/service_center/show_external/4655"