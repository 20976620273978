import { RECEIVE_USER_STORAGE_STATEMENTS, RECEIVE_USER_STORAGE_STATEMENT } from 'actions/storage_tracker/storage_usage_statements_actions'
import merge from 'lodash/merge'

const userStorageStatementsReducer = (state = {}, action) => {
  Object.freeze(state)

  switch(action.type) {
    case RECEIVE_USER_STORAGE_STATEMENTS:
      return action.storageUsageStatements
    case RECEIVE_USER_STORAGE_STATEMENT:
      const newStatement = { [action.storageUsageStatement.id]: action.storageUsageStatement}
      return merge({}, state, newStatement)
    default:
      return state
  }
}

export default userStorageStatementsReducer
