import React from 'react'

import { Button } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilePdf } from '@fortawesome/free-regular-svg-icons'
import { forEach } from 'lodash'

export default class StorageStatementButton extends React.Component {

  openFiles = () => {
    const { urls } = this.props

    // Theoretically, this could have multiple files attached
    forEach(Object.values(urls), url => {
      window.open(`${url}`)      
    })
  }

  openStatementsButton = urls => (
    <Button onClick={this.openFiles}><FontAwesomeIcon icon={faFilePdf}/> Download</Button>
  )

  noStatementAvailableButton = () => (
    <Button disabled>No statement available</Button>
  )

  render() {
    const { urls } = this.props

    if (urls) {
      return this.openStatementsButton()
    } else {
      return this.noStatementAvailableButton()
    }
  }
}