import React from 'react'
import { LinkContainer } from 'react-router-bootstrap'
import { Button, Nav, Navbar } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from "@fortawesome/free-solid-svg-icons"
import NavbarToggle from 'react-bootstrap/NavbarToggle';

export default class SiteNavbar extends React.PureComponent {
  render() {
    return(
      <Navbar sticky="top" variant="dark" bg="primary" expand="md">
        <Navbar.Brand>Scientific Computing Unit</Navbar.Brand>
        <Navbar.Toggle as="button" variant="secondary" aria-controls="site-nav">
          <FontAwesomeIcon icon={faBars} />
        </Navbar.Toggle>
        <Navbar.Collapse id="site-nav">
          <Nav className="ml-auto">
            <Nav.Item className="mx-3">
              <Nav.Link href="#publications">
                Publications
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-3">
              <Nav.Link href="#services">
                Services
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className="mx-3">
              <Nav.Link href="#faq">
                FAQ
              </Nav.Link>
            </Nav.Item>
            <LinkContainer to="/login">
              <Button variant="secondary">Login</Button>
            </LinkContainer>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  }
}
