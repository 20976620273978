import { RECEIVE_GROUPS } from 'actions/token_authority/groups_actions'

const groupsReducer = ( state={}, action ) => {
  Object.freeze(state)
  
  switch(action.type) {
    case RECEIVE_GROUPS:
      return action.groups
    default:
      return state
  }
}

export default groupsReducer