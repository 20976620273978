import React from 'react'

import { Row, Col, ListGroup } from 'react-bootstrap'

export default class SiteFooter extends React.PureComponent {
  render() {
    return(
      <Row>
        <Col>
          <ListGroup className="my-5 justify-content-center list-group-horizontal-md site-footer">
            <ListGroup.Item>&copy; Weill Cornell Medicine.</ListGroup.Item>
            <ListGroup.Item><a href="http://weill.cornell.edu/legal/">Privacy &amp; Legal</a></ListGroup.Item>
            <ListGroup.Item><a href="http://weill.cornell.edu/careers">Careers</a></ListGroup.Item>
            <ListGroup.Item><a href="http://give.weill.cornell.edu">Giving</a></ListGroup.Item>
            <ListGroup.Item><a href="https://directory.weill.cornell.edu/">Directory</a></ListGroup.Item>
            <ListGroup.Item><a href="https://weill.cornell.edu/accessibility">Web Accessibility Assistance</a></ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>
    )
  }
}
