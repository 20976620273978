import React from 'react'
import Table from 'components/tables/table'
import { generateUsersTable } from 'util/table_helpers/users_table_helpers.js'

export default class GroupsPermissionsTable extends React.PureComponent {
  render() {
    const tableData = generateUsersTable(this.props.users)
    return(
      <Table tableData={tableData} />
    )
  }
}