import { includes, get } from 'lodash'


// Helper to decode a JSON web token as created by the
// SCU Token Authority API. See details about the token content here:
// https://scu.med.cornell.edu/git/maurice/token_authority_api

const jwtDecode = require('jwt-decode');

// Decode a JWT. Note that this won't do any validation, any well-formed token will be decoded
const decodeToken = token => (
  token ? jwtDecode(token) : null
)

// Get the JTI claim from a token. To be used for validation elsewhere
export const getJti = token => {
  return (
    get(decodeToken(token), "jti") || ""
  )
}

// Get the permissions hash from the token data
const permissions = token => (
  get(decodeToken(token), "data.permissions") || {}
)

// Get the application roles from the token permissions hash
const applicationRoles = (appName, token) => (
  get(permissions(token), `${appName}.role`) || []
)

// Check if this token contains a specific role for a specific application
// Again, keep in mind that this is not validated. Only use this to p.e.
// show or hide a specific UI element, but do not allow this to execute
// any non-permitted action without proper validation on the backend
export const hasRole = (token, application, role) => (
  includes(applicationRoles(application, token), role)
)
