import { RECEIVE_APPLICATION, RECEIVE_APPLICATIONS } from 'actions/token_authority/applications_actions'
import merge from 'lodash/merge'

const applicationsReducer = ( state={}, action ) => {
  Object.freeze(state)
  
  switch(action.type) {
    case RECEIVE_APPLICATION:
      const newApp = { [action.application.id]: action.application }
      return merge({}, state, newApp)
    case RECEIVE_APPLICATIONS:
      return action.applications
    default:
      return state
  }
}

export default applicationsReducer