import React from 'react'

import { LinkContainer } from "react-router-bootstrap";
import { Button } from 'react-bootstrap'


export default class ApplicationsListItem extends React.PureComponent {
  render() {
    const { application } = this.props
    const { name } = application

    return(
      <LinkContainer exact to={`/token_authority/applications/${application.id}`}>
        <Button className="mx-3">{ name }</Button>
      </LinkContainer>
    )
  }
}