import { RECEIVE_FILESET_STORAGE_STATEMENTS } from 'actions/storage_tracker/storage_usage_statements_actions'

const filesetStorageStatementsReducer = (state = {}, action) => {
  Object.freeze(state)

  switch(action.type) {
    case RECEIVE_FILESET_STORAGE_STATEMENTS:
      return action.storageUsageStatements
    default:
      return state
  }
}

export default filesetStorageStatementsReducer
