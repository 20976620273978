import React from 'react'
import Table from 'components/tables/table'
import { generateGroupsTable } from 'util/table_helpers/groups_table_helpers.js'

export default class GroupsPermissionsTable extends React.PureComponent {
  render() {
    const tableData = generateGroupsTable(this.props.groups)
    return(
      <Table tableData={tableData} />
    )
  }
}