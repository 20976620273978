import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import InternalNavbar from 'components/internal_navbar/internal_navbar'

const mapStateToProps = (state, { match }) => {
  const { auth } = state
  return {
    match: match,
    auth
  }
}

const mapDispatchToProps = dispatch => ({
  
})

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(InternalNavbar))