import { RECEIVE_SERVICE_USERS, RECEIVE_SERVICE_USER } from 'actions/hpc_tracker/service_user_actions';
import merge from 'lodash/merge';

const serviceUserReducer = (state = {}, action) => {
  Object.freeze(state);

  switch(action.type) {
    case RECEIVE_SERVICE_USERS:
      return action.serviceUsers;
    case RECEIVE_SERVICE_USER:
      const newServiceUser = {[action.serviceUser.id]: action.serviceUser};
      return merge({}, state, newServiceUser);
    default:
      return state;
  }
};

export default serviceUserReducer;